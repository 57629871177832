export const requiredFields = ['GUID', 'ENTITY', "POSTER_TITLE", "MAILINGID", "MAILING_REFERENCE_NUMBER", "PACKAGE_ID",     'ISSUE', 'RWODATE', 'LID', 'CONTACT_NAME', 'TITLE', 'CONTACT_SUFFIX', 'COMPANY', 'DEPARTMENT', 'ADDRESS1', 'ADDRESS2', 'STE/FLOOR', 'CITY', 'STATE', 'ZIP', 'PHONE', 'POSTER_CODE_1', 'POSTER_QTY_1', 'POSTER_CODE_2', 'POSTER_QTY_2', 'POSTER_CODE_3', 'POSTER_QTY_3', 'TOTALPOSTERS', 'AFFIX_BROCHURE_CODE_1', 'AFFIX_BROCHURE_QTY_1', 'AFFIX_BROCHURE_CODE_2', 'AFFIX_BROCHURE_QTY_2', 'AFFIX_BROCHURE_CODE_3', 'AFFIX_BROCHURE_QTY_3', 'BROCHURE_CODE_1', 'BROCHURE_QTY_1', 'LETTER_1', 'LETTER_QTY_1', 'LETTER_2', 'LETTER_QTY_2', 'LETTER_3', 'LETTER_QTY_3', 'PACKAGING', 'BROCHURE_CODE_2', 'BROCHURE_QTY_2', 'BROCHURE_CODE_3', 'BROCHURE_QTY_3', 'ADHESIVE_1', 'ADHESIVE_QTY_1', 'ADHESIVE_2', 'ADHESIVE_QTY_2', 'ADHESIVE_3', 'ADHESIVE_QTY_3', 'VINYL_ENV_1', 'VINYL_ENV_QTY_1', 'VINYL_ENV_2', 'VINYL_ENV_QTY_2', 'VINYL_ENV_3', 'VINYL_ENV_QTY_3', 'ADD_1', 'ADD_2', 'ADD_3', 'RWOLISTNAME', 'ADDRESSVERIFIED', 'Thickness', 'PackagingWeight', 'PIWeight', 'VinylWeight', 'AdhesiveWeight', 'PosterWeight', 'LetterWeight', 'TotalWeight', 'MailClass'];


export const Hanger_Comps =[
  'GUID', 'ENTITY','ISSUE', 'RWODATE', 'LID', 'CONTACT_NAME', 'TITLE', 'CONTACT_SUFFIX', 'COMPANY', 'DEPARTMENT', 'ADDRESS1', 'ADDRESS2', 'STE/FLOOR', 'CITY', 'STATE', 'ZIP', 'PHONE', 'POSTER_CODE_1', 'POSTER_QTY_1', 'POSTER_CODE_2', 'POSTER_QTY_2', 'POSTER_CODE_3', 'POSTER_QTY_3', 'TOTALPOSTERS', 'AFFIX_BROCHURE_CODE_1', 'AFFIX_BROCHURE_QTY_1', 'AFFIX_BROCHURE_CODE_2', 'AFFIX_BROCHURE_QTY_2', 'AFFIX_BROCHURE_CODE_3', 'AFFIX_BROCHURE_QTY_3', 'BROCHURE_CODE_1', 'BROCHURE_QTY_1', 'LETTER_1', 'LETTER_QTY_1', 'LETTER_2', 'LETTER_QTY_2', 'LETTER_3', 'LETTER_QTY_3', 'PACKAGING', 'BROCHURE_CODE_2', 'BROCHURE_QTY_2', 'BROCHURE_CODE_3', 'BROCHURE_QTY_3', 'ADHESIVE_1', 'ADHESIVE_QTY_1', 'ADHESIVE_2', 'ADHESIVE_QTY_2', 'ADHESIVE_3', 'ADHESIVE_QTY_3', 'VINYL_ENV_1', 'VINYL_ENV_QTY_1', 'VINYL_ENV_2', 'VINYL_ENV_QTY_2', 'VINYL_ENV_3', 'VINYL_ENV_QTY_3', 'ADD_1', 'ADD_2', 'ADD_3', 'RWOLISTNAME', 'ADDRESSVERIFIED', 'Thickness', 'PackagingWeight', 'PIWeight', 'VinylWeight', 'AdhesiveWeight', 'PosterWeight', 'LetterWeight', 'TotalWeight', 'MailClass'
]


 export const monthss = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  export const fields = [
    'POSTER_CODE',
    'Template_Name',
    'QR1/Article 1/Column 1',
    'QR2_ENL',
    'QR3_POD',
    'QR4_POLL',
    // 'QR5_3COL',
    'QR5_3COL/Article 3/Column 3',
    'QR6_Perks',
    'QR7',
    'QR8',
    'QR9',
    'QR10',
    // 'Article 2',
    'Article 2/Column 2',
    // 'Article 1',
    'Article 4/Column 4_A1',
    // 'Article 3',
    'Article 5/Column 4_A2',
    'Article 6/Column 4_A3',
    'Article 7/Column 4_A4',
    'PDF_Manual',
    'PDF_AI',
    'PDF_AD_1',
    'PDF_AD_2',
    'PDF_URL'
  ];

  export const templateFields = {
    "1-ICYMI": [
      "QR1/Article 1/Column 1",
      "QR5_3COL/Article 3/Column 3",
      "Article 4/Column 4_A1",
      "Article 2/Column 2",
      "Article 5/Column 4_A2",
    ],
    "1-ICYMI-CARTOON": ["QR4_POLL"],
    "2-ICYMI-POLL": [
      "QR1/Article 1/Column 1",
      "Article 6/Column 4_A3",
      "Article 7/Column 4_A4",
      "Article 4/Column 4_A1",
      "Article 2/Column 2",
      "Article 5/Column 4_A2",
    ],
  };