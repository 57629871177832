import { API } from 'api/API';
import axios from 'axios';
import UAParser from 'ua-parser-js';

// Getting Device Information from this function
export function getDeviceInfo() {
  const parser = new UAParser();
  const result = parser.getResult();
  return {
    device: result.device.type || 'Desktop', // If type is undefined, it's a Desktop
    browser: result.browser.name,
    browserVersion: result.browser.version,
    os: result.os.name,
    osVersion: result.os.version,
  };
}

// Getting Screen Resolution From this Function
export function getScreenResolution() {
    return {
      width: window.screen.width,
      height: window.screen.height,
    };
  }
  
// Getting IP Address
export async function getIPAddress() {
  try {
    const response = await axios.get(API.Get_IP_Address);
    return response.data.ip;
  } catch (error) {
    console.error("Failed to fetch IP address:", error);
    return 'Unknown';
  }
}
