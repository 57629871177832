import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Autocomplete,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  Paper,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { FaFilePdf } from "react-icons/fa6";
import { MdOutlineRemoveCircle } from "react-icons/md";
import { API } from "api/API";
import Axios from "api/Axios";

import "../style.css";
import { FaDownload } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import { RiEyeCloseFill } from "react-icons/ri";
import { MdDelete } from "react-icons/md";

const validationSchema = Yup.object().shape({
  template: Yup.mixed().required("Please select a Template"),
  manual_file: Yup.mixed().required("Manual File is required"),
  ad_file: Yup.mixed().required("Ad File is required"),
  icymi_files: Yup.array()
    .ensure()
    .min(1, "At least one ICYMI File is required")
    .of(Yup.mixed().required("ICYMI File is required")),
});

const UploadAssets = ({
  close,
  setSnackbar,
  setIsLoading,
  uploadAssetsData,
  setassetsData,
  ChildListing,
  isLoading,
  SubListing,
  uploadCheck,
  ...others
}) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const [data, setData] = useState([]);
  const [template, setTemplate] = useState([]);
  const tagsContainerRef = useRef(null);

  // Without Validation
  // const createFileChangeHandler = (fieldName, setFieldValue) => (event) => {
  //   const file = event.target.files[0];
  //   if (file && file.name.toLowerCase().endsWith(".pdf")) {
  //     setFieldValue(fieldName, file);
  //   } else {
  //     setSnackbar({
  //       open: true,
  //       severity: "error",
  //       message: `Please select PDF Only for ${fieldName} File`,
  //     });
  //   }
  // };

  // With Validation
  const createFileChangeHandler = (fieldName, setFieldValue) => (event) => {
    const file = event.target.files[0];
    if (file && file.name.toLowerCase().endsWith(".pdf")) {
      let poster_code = replaceSlashWithDash(uploadAssetsData.post_code);
      const month = uploadAssetsData.month.slice(0, 3).toUpperCase();
      const year = uploadAssetsData.year.slice(2);
      const expectedFileName = `${poster_code}_${month}${year}_${
        fieldName === "manual_file" ? "Manual" : "Ad_1"
      }.pdf`;
      if (file.name === expectedFileName) {
        setFieldValue(fieldName, file);
      } else {
        setSnackbar({
          open: true,
          severity: "error",
          message: `Incorrect file name: Suggested File Name is ${expectedFileName}.`,
        });
        event.target.value = "";
      }
    } else {
      setSnackbar({
        open: true,
        severity: "error",
        message: `Please select PDF Only for ${fieldName} File`,
      });
      event.target.value = "";
    }
  };

  const ICYMIFileChangeHandler = (fieldName, setFieldValue) => (event) => {
    let files = event.target.files;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file && file.name.toLowerCase().endsWith(".pdf")) {
        let poster_code = replaceSlashWithDash(uploadAssetsData.post_code);
        const month = uploadAssetsData.month.slice(0, 3).toUpperCase();
        const year = uploadAssetsData.year.slice(2);
        const optionNumber = i + 1; // Increment option number for each file
        const expectedFileName = `${poster_code}_${month}${year}_ICYMI_Option_${optionNumber}.pdf`;

        if (file.name === expectedFileName) {
          console.log("File name matched:", file.name);
          setFieldValue(fieldName, files);

          // console.log(file, 'hello this is file here')
        } else {
          setSnackbar({
            open: true,
            severity: "error",
            // message: `File Name Not Matched. The suggested file name for option ${optionNumber} will be ${expectedFileName}.`,
            message: `File Name Not Matched, Please upload correct version.`,
          });
          event.target.value = "";
          return; // Exit loop if any file name doesn't match
        }
      } else {
        setSnackbar({
          open: true,
          severity: "error",
          message: `Please select PDF Only`,
        });
        event.target.value = "";
        setFieldValue(fieldName, []);
        return; // Exit loop if any file is not a PDF
      }
    }
    if (data && data.ICYMI && files.length < data.ICYMI.length) {
      setSnackbar({
        open: true,
        severity: "error",
        message: `Files count does not match with the uploaded PDF`,
      });
      event.target.value = "";
      setFieldValue(fieldName, []);
    }
  };

  // with Edit function
  const handleSubmit = async (values) => {
    const formData = new FormData();
    // console.log(data, 'hello I am dat')
    if (data.length === 0) {
      formData.append("manual", values.manual_file);
      formData.append("ad", values.ad_file);
      formData.append("template_name", values.template);
      const icymiFiles = Array.from(values.icymi_files);
      icymiFiles.forEach((file) => {
        formData.append("ICYMI", file);
      });
      formData.append("year", uploadAssetsData.year);
      formData.append("month", uploadAssetsData.month);
      formData.append("post_order", uploadAssetsData.post_order);
      formData.append("post_code", uploadAssetsData.post_code);
      formData.append("merged_name", uploadAssetsData.merged_name);
      formData.append("batch_number", uploadAssetsData.batch_number);
      formData.append("file_name", uploadAssetsData.file_name);
      try {
        setIsLoading(true);
        const response = await Axios.Filepost(
          API.Upload_PrePrint_Assets,
          formData
        );
        if (response.status === 200) {
          setIsLoading(false);
          // console.log(response.data, "response");
          setSnackbar({
            open: true,
            message: response.data.msg,
            severity: "success",
          });
          ChildListing();
          SubListing(uploadCheck.pre_print_mergename_id);
          close();
        }
      } catch (err) {
        console.log(err, "Error while getting API response");
        setIsLoading(false);
        setSnackbar({
          open: true,
          message: err.response.data.error,
          severity: "error",
        });
      }
    } else if (data) {
      console.log(values, "hello Values");
      if (
        values.manual_file === "" &&
        values.ad_file === "" &&
        values.icymi_files.length === 0
      ) {
        setSnackbar({
          open: true,
          message: "Please enter atleast one Input",
          severity: "error",
        });
      } else {
        // formData.append("manual", values.manual_file=== undefined || values.manual_file === "" ?   : values.manual_file);
        // formData.append("ad", values.ad_file === undefined || values.ad_file === "" ? undefined : values.ad_file);
        if (values.manual_file !== "") {
          formData.append("manual", values.manual_file);
        }

        if (values.ad_file !== "") {
          formData.append("ad", values.ad_file);
        }

        formData.append("template_name", data.template_name);
        const icymiFiles = Array.from(values.icymi_files);
        icymiFiles.forEach((file) => {
          formData.append("ICYMI", file);
        });
        formData.append("year", uploadAssetsData.year);
        formData.append("month", uploadAssetsData.month);
        formData.append("post_order", uploadAssetsData.post_order);
        formData.append("post_code", uploadAssetsData.post_code);
        formData.append("merged_name", uploadAssetsData.merged_name);
        formData.append("batch_number", uploadAssetsData.batch_number);
      formData.append("file_name", uploadAssetsData.file_name);
        try {
          setIsLoading(true);
          const response = await Axios.Filepost(API.Edit_Assets, formData);
          if (response.status === 200) {
            setIsLoading(false);
            // console.log(response.data, "response");
            setSnackbar({
              open: true,
              message: response.data.message,
              severity: "success",
            });
            ChildListing();
            SubListing(uploadCheck.pre_print_mergename_id);
            close();
          }
        } catch (err) {
          console.log(err, "Error while getting API response");
          setIsLoading(false);
          setSnackbar({
            open: true,
            message: err.response.data.error,
            severity: "error",
          });
        }
      }
    }
  };

  const getData = async () => {
    const data = {
      merged_name: uploadAssetsData.merged_name,
      post_order: uploadAssetsData?.post_order,
      post_code: uploadAssetsData.post_code,
      file_name: uploadAssetsData.file_name
    };
    try {
      setIsLoading(true);
      const response = await Axios.post(API.GetAssets, data);
      if (response.status === 200) {
        // console.log(response.data.data[0], "response");
        setIsLoading(false);
        setData(response.data.data[0]);
        setassetsData(response.data.data[0]);
      }
    } catch (err) {
      console.log(err, "Error while getting Categories");
      setIsLoading(false);
      setData([]);
      setassetsData([]);
    }
  };

  const handleDownloadPDF = async (url) => {
    try {
      setIsLoading(true);
      const response = await Axios.post(API.Download_Assets, { url });
      if (response.status === 200) {
        setIsLoading(false);
        const blob = response.data.dowanloadUrl;
        const url = blob;
        // Create a link and trigger a click on it to start the download
        const link = document.createElement("a");
        link.download = "my-image-name.jpeg";
        link.href = url;
        link.target = "_blank";
        link.click();
        URL.revokeObjectURL(url);
      }
    } catch (err) {
      console.log(err, "Error while getting Categories");
      setIsLoading(false);
    }
  };

  // Get Template API
  const GetTemplates = async () => {
    try {
      const response = await Axios.get(API.Get_Templates);
      if (response.status === 200) {
        // console.log(response.data, "hello i am response");
        setTemplate(response.data);
      }
    } catch (err) {
      console.log(err, "Error while getting Categories");
    }
  };

  const replaceSlashWithDash = (inputString) => {
    return inputString.replace(/\//g, "-");
  };

  const DeleteICYMIAssets = async () => {
    const data = {
      merged_name: uploadAssetsData.merged_name,
      post_order: uploadAssetsData?.post_order,
      post_code: uploadAssetsData.post_code,
      file_name: uploadAssetsData.file_name
    };
    try {
      setIsLoading(true);
      const response = await Axios.post(API.Delete_ICYMI_Assets, data);
      if (response.status === 200) {
        // console.log(response.data.data[0], "response");
        setIsLoading(false);
        setSnackbar({
          open: true,
          message: response.data.message,
          severity: "success",
        });
        close();
        ChildListing();
      }
    } catch (err) {
      console.log(err, "Error while getting Categories");
      setIsLoading(false);
      setData([]);
      setassetsData([]);
    }
  };

  // console.log(data.length, "hello I am data");

  useEffect(() => {
    getData();
    GetTemplates();
  }, []);

  return (
    <div style={{ marginTop: -28 }}>
      <Formik
        initialValues={{
          manual_file: "",
          ad_file: "",
          icymi_files: [],
          template: "",
        }}
        validationSchema={data.length === 0 ? validationSchema : null}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          // console.log(values, "hello values");
          handleSubmit(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          values,
        }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            {...others}
            autoComplete="off"
          >
            <Grid
              container
              spacing={matchDownSM ? 0 : 2}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Grid item xs={12} style={{ marginTop: 40 }}>
                {data && data.template_name ? (
                  <TextField
                    fullWidth
                    label="Select Template"
                    variant="outlined"
                    value={data.template_name}
                    disabled
                  />
                ) : (
                  <FormControl
                    fullWidth
                    error={Boolean(touched.template && errors.template)}
                    sx={{ ...theme.typography.customInput, margin: 0 }}
                  >
                    <Autocomplete
                      className="templateLabel"
                      autoHighlight
                      options={template}
                      getOptionLabel={(option) => option.Template_Name || ""}
                      key={(option) => option.id}
                      renderInput={(params) => (
                        <TextField
                          id="outlined-basic"
                          {...params}
                          label="Select Template"
                          variant="outlined"
                          error={Boolean(touched.template && errors.template)}
                        />
                      )}
                      name="template"
                      value={
                        values.template
                          ? template.find(
                              (option) =>
                                option.Template_Name === values.template
                            )
                          : null
                      }
                      onChange={(_, newValue) => {
                        setFieldValue(
                          `template`,
                          newValue ? newValue.Template_Name : ""
                        );
                      }}
                      noOptionsText="No Results Found"
                    />
                  </FormControl>
                )}
                {touched.template && errors.template && (
                  <FormHelperText
                    error
                    id="standard-weight-helper-text-template"
                    sx={{ marginLeft: 2 }}
                  >
                    {errors.template}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={data.manual ? 11 : 12}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.manual_file && errors.manual_file)}
                  sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-manual_file">
                    Manual File
                  </InputLabel>
                  <Input
                    id="outlined-adornment-manual_file"
                    type="file"
                    name="manual_file"
                    inputProps={{
                      accept: ".pdf",
                    }}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      createFileChangeHandler("manual_file", setFieldValue)(e);
                    }}
                    endAdornment={
                      <InputAdornment
                        sx={{ fontSize: 22, marginTop: 2 }}
                        position="end"
                      >
                        <FaFilePdf />
                      </InputAdornment>
                    }
                  />
                  {touched.manual_file && errors.manual_file && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-manual_file"
                    >
                      {errors.manual_file}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {data.manual && (
                <Grid item xs={1}>
                  <a href={data.manual} target="_blank">
                    <FaDownload
                      // onClick={() => handleDownloadPDF(data.manual)}
                      style={{ marginTop: 33 }}
                      color="black"
                      fontSize={20}
                    />
                  </a>
                </Grid>
              )}

              <Grid item xs={data.ad ? 11 : 12}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.ad_file && errors.ad_file)}
                  sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-ad_file">
                    Ad File
                  </InputLabel>
                  <Input
                    id="outlined-adornment-ad_file"
                    type="file"
                    name="ad_file"
                    inputProps={{
                      accept: ".pdf",
                    }}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      createFileChangeHandler("ad_file", setFieldValue)(e);
                    }}
                    endAdornment={
                      <InputAdornment
                        sx={{ fontSize: 22, marginTop: 2 }}
                        position="end"
                      >
                        <FaFilePdf />
                      </InputAdornment>
                    }
                  />
                  {touched.ad_file && errors.ad_file && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-ad_file"
                    >
                      {errors.ad_file}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {data.ad && (
                <Grid item xs={1}>
                  <a href={data.ad} target="_blank">
                    <FaDownload
                      // onClick={() => handleDownloadPDF(data.ad)}
                      style={{ marginTop: 33 }}
                      color="black"
                      fontSize={20}
                    />
                  </a>
                </Grid>
              )}

              <Grid item xs={12}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.icymi_files && errors.icymi_files)}
                  sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-icymi_files">
                    ICYMI Files
                  </InputLabel>
                  <Input
                    id="outlined-adornment-icymi_files"
                    type="file"
                    name="icymi_files"
                    inputProps={{
                      accept: ".pdf",
                      multiple: true,
                    }}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      ICYMIFileChangeHandler("icymi_files", setFieldValue)(e);
                      // setFieldValue("icymi_files", e.target.files);
                    }}
                    endAdornment={
                      <InputAdornment
                        sx={{ fontSize: 22, marginTop: 2 }}
                        position="end"
                      >
                        <FaFilePdf />
                      </InputAdornment>
                    }
                  />
                  {touched.icymi_files && errors.icymi_files && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-icymi_files"
                    >
                      {errors.icymi_files}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              {data?.ICYMI && (
                <Grid item xs={11}>
                  {data?.ICYMI?.map((item, index) => (
                    <p>
                      ICYMI File {index + 1}:
                      <a href={item} target="_blank">
                        ICYMI {index + 1}
                      </a>
                    </p>
                  ))}
                </Grid>
              )}
              <Grid item xs={1}>
                {data.ICYMI && data?.ICYMI?.length > 0 && (
                  <MdDelete
                    style={{ cursor: "pointer", fontSize: 20 }}
                    onClick={DeleteICYMIAssets}
                  />
                )}
              </Grid>
            </Grid>

            <div style={{ textAlign: "center", marginTop: 10 }}>
              <Button
                size="large"
                variant="contained"
                style={{
                  background: "#C62828",
                  marginRight: "10px",
                  color: isLoading === true && "#fff",
                }}
                onClick={close}
                disabled={isLoading === true ?? true}
              >
                Close
              </Button>
              <Button
                size="large"
                type="submit"
                variant="contained"
                style={{
                  background: "#1d213e",
                  marginRight: "15px",
                  color: isLoading === true && "#fff",
                }}
                disabled={isLoading === true ?? true}
              >
                Submit
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default UploadAssets;
