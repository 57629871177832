import React, { useState } from "react";
import {
  Button,
  TextField,
  Box,
  Grid,
  FormControl,
  InputLabel,
  FormHelperText,
  OutlinedInput,
  useMediaQuery,
  Input,
  InputAdornment,
} from "@mui/material";
import * as Yup from "yup";
import { useTheme } from "@mui/material/styles";
import { Formik } from "formik";
import { FaFileCsv } from "react-icons/fa";
import Papa from "papaparse";

import Axios from "api/Axios";
import { API } from "api/API";
import { Hanger_Comps, requiredFields } from "./Fields";
import "./style.css";

const validationSchema = Yup.object().shape({
  postOrder: Yup.number()
    .required("Print Order  is required")
    .min(1, "Print order number must be at least 1")
    .max(9, "Print order number must be at most 9")
    .integer("Batch Number must be an integer"),
  batchNumber: Yup.number()
    .required("Batch Number  is required")
    .min(1, "Batch Number must be at least 1")
    .max(9, "Batch Number must be at most 9")
    .integer("Batch Number must be an integer"),
});

const PrePrintForm = ({
  setSnackbar,
  setIsLoading,
  close,
  value,
  apiCall,
  Get_All_listings,
  page,
  SubListing,
  RWO_Type,
  isLoading,
  RowClose,
  uploadAssetsData,
  ...others
}) => {
  const [fileData, setFileData] = useState([]);
  const MonthYear = value.month.slice(0, 3) + value.year.slice(2, 4);
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));

  const handleFile = async (values, printData) => {
    const APIendPoint =
      (RWO_Type.RWO === true && API.ADD_CSV) ||
      (RWO_Type.hanger === true && API.Add_hanger_Comp_Data) ||
      (RWO_Type.comp === true && API.Add_hanger_Comp_Data);
    try {
      const merged_name = `${value.month} ${value.year}`;
      const formData = new FormData();
      formData.append("post_order", values.postOrder);
      formData.append("batch_number", values.batchNumber);
      formData.append("csvfile", values.rwo_file);
      formData.append(
        "pre_print_merge_id",
        printData === undefined ? value.printid : printData
      );
      formData.append("merged_name", merged_name);
      if (RWO_Type.hanger === true) {
        formData.append("type", "hanger");
      }
      if (RWO_Type.comp === true) {
        formData.append("type", "comp");
      }
      setIsLoading(true);
      // const result = await Axios.Filepost(API.ADD_CSV, formData);
      const result = await Axios.Filepost(APIendPoint, formData);
      if (result.status === 200) {
        setIsLoading(false);
        //   console.log(result.data, 'hello this is data')
        RowClose();
        
        setSnackbar({
          open: true,
          severity: "success",
          message: result.data.message,
        });
        if (apiCall === "Yes") {
          await Get_All_listings(page);
          close();
        } else {
          await SubListing(value.printid);
          close();
        }
      }
    } catch (error) {
      console.log(error, "Error while uploading the Data");
      setIsLoading(false);
      setSnackbar({
        open: true,
        severity: "error",
        message: error.response.data.error,
      });
    }
  };

  const AddRWOFile = async (values) => {
    let printData = undefined;
    const data = {
      year: value.year,
      month: value.month,
      merged_name: `${value.month} ${value.year}`,
    };
    try {
      if (apiCall === "Yes") {
        const response = await Axios.post(API.PrePrintModule, data);
        if (response.status === 201) {
          setIsLoading(false);
          printData = response.data.newMergename.pre_print_id;
          // setSnackbar({
          //   open: true,
          //   severity: "success",
          //   message: response.data.msg,
          // });
          await handleFile(values, printData);
        }
      } else if (apiCall === "No") {
        await handleFile(values, printData);
      }
    } catch (error) {
      console.log(error, "Error to ADD RWO");
      setIsLoading(false);
      setSnackbar({
        open: true,
        severity: "error",
        message: error.response.data.error,
      });
    }
  };

  const handleRWO = async (values) => {
    try {
      if (!values.rwo_file) {
        setSnackbar({
          open: true,
          severity: "warning",
          message: "Please select the RWO File",
        });
      } else {
        AddRWOFile(values);
      }
    } catch (error) {
      setIsLoading(false);
      setSnackbar({
        open: true,
        severity: "error",
        message: error.response.data.error,
      });
      console.log(error, "Error while submitting the file and details");
    }
  };

  return (
    <div>
      <TextField
        style={{ marginTop: "8px" }}
        type="text"
        value={`${value.month} ${value.year}`}
        disabled
        variant="outlined"
        label="Selected Month and Year"
        fullWidth
      />
      <Formik
        initialValues={{
          postOrder: value.print_order || "",
          batchNumber: value.batch_number || "",
          rwo_file: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          handleRWO(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          values,
          dirty,
          resetForm,
        }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            {...others}
            autoComplete="off"
          >
            <Grid container spacing={matchDownSM ? 0 : 2}>
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.postOrder && errors.postOrder)}
                  sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-postOrder">
                    Print Order
                  </InputLabel>
                  <OutlinedInput
                    disabled={
                      (RWO_Type.hanger === true && true) ||
                      (RWO_Type.comp === true && true)
                    }
                    id="outlined-adornment-postOrder"
                    type="number"
                    value={values.postOrder}
                    name="postOrder"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputProps={{}}
                    onKeyPress={(e) => {
                      if (e.key === "e") {
                        e.preventDefault();
                      }
                    }}
                  />
                  {touched.postOrder && errors.postOrder && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-postOrder"
                    >
                      {errors.postOrder}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.batchNumber && errors.batchNumber)}
                  sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-batchNumber">
                    Batch Number
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-batchNumber"
                    type="number"
                    disabled={
                      (RWO_Type.hanger === true && true) ||
                      (RWO_Type.comp === true && true)
                    }
                    value={values.batchNumber}
                    name="batchNumber"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputProps={{}}
                    onKeyPress={(e) => {
                      if (e.key === "e") {
                        e.preventDefault();
                      }
                    }}
                  />
                  {touched.batchNumber && errors.batchNumber && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-batchNumber"
                    >
                      {errors.batchNumber}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            {values.batchNumber && values.postOrder && (
              <>
                <FormControl
                  fullWidth
                  error={Boolean(touched.rwo_file && errors.rwo_file)}
                  sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-rwo_file">
                  {RWO_Type.hanger === true && 'Hanger File' || RWO_Type.comp === true && 'Comp File' || RWO_Type.RWO === true && "RWO File" }                  </InputLabel>
                  <Input
                    id="outlined-adornment-rwo_file"
                    type="file"
                    name="rwo_file"
                    inputProps={{
                      accept: ".csv",
                    }}
                    onBlur={handleBlur}
                    onChange={(event) => {
                      const file = event.target.files[0];
                      if (file && file.name.toLowerCase().endsWith(".csv")) {
                        // const expectedFileName = `${value.month} ${value.year} PO ${values.postOrder} RWO_Batch ${values.batchNumber}.csv`;
                        const expectedFileName =
                          (RWO_Type.RWO === true &&
                            `${value.month} ${value.year} PO ${values.postOrder} RWO_Batch ${values.batchNumber}.csv`) ||
                          (RWO_Type.hanger === true &&
                            `${value.month} ${value.year} PO ${values.postOrder} RWO_Batch ${values.batchNumber} Hangers.csv`) ||
                          (RWO_Type.comp === true &&
                            `${value.month} ${value.year} PO ${values.postOrder} RWO_Batch ${values.batchNumber} Comps and Letters.csv`);
                        if (file.name === expectedFileName) {
                          Papa.parse(file, {
                            complete: (result) => {
                              const data = result.data[0];
                              const allFieldsPresent = RWO_Type.RWO === true && requiredFields.every((field) => data.hasOwnProperty(field)) || RWO_Type.hanger  === true &&  Hanger_Comps.every((field) => data.hasOwnProperty(field)) || RWO_Type.comp  === true &&  Hanger_Comps.every((field) => data.hasOwnProperty(field)) ;
                              //   console.log(allFieldsPresent, 'allFieldsPresent')
                              if (!allFieldsPresent) {
                                setSnackbar({
                                  open: true,
                                  severity: "warning",
                                  message:
                                    "Selected file does not match the RWO format.",
                                });
                                setFieldValue("rwo_file", undefined);
                              } else if (allFieldsPresent === true) {
                                setFieldValue("rwo_file", file);
                              }
                            },
                            header: true, // Assumes the first row in the Excel file contains column headers
                          });
                        } else {
                          setSnackbar({
                            open: true,
                            severity: "warning",
                            message: `File Name Not Matched. The suggested file name will be ${expectedFileName}.`,
                          });
                          setFieldValue("rwo_file", undefined);
                        }
                      } else {
                        setSnackbar({
                          open: true,
                          severity: "warning",
                          message: "Only CSV file is accepted",
                        });
                        setFieldValue("rwo_file", undefined);
                        console.error(
                          "Invalid file type. Please select a CSV file."
                        );
                      }
                      //   event.target.value = null;
                    }}
                    endAdornment={
                      <InputAdornment
                        sx={{ fontSize: 22, marginTop: 2 }}
                        position="end"
                      >
                        <FaFileCsv />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </>
            )}

            <div style={{ textAlign: "center", marginTop: 10 }}>
              <Button
                size="large"
                variant="contained"
                style={{
                  background: "#C62828",
                  marginRight: "10px",
                  color: isLoading === true && "#fff",
                }}
                onClick={close}
                disabled={isLoading === true ?? true}
              >
                Close
              </Button>
              <Button
                size="large"
                type="submit"
                variant="contained"
                style={{
                  background: "#1d213e",
                  marginRight: "15px",
                  color: isLoading === true && "#fff",
                }}
                disabled={isLoading === true ?? true}
              >
                Submit
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default PrePrintForm;
