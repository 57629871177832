// import React, { useRef, useState } from "react";
// import Popup from "components/Popup";
// import { Button, Box, Typography, Slider } from "@mui/material";
// import Cropper from "react-easy-crop";

// const ImageCrop = ({ openPOP_url, closedPOP, cropedImageSrc }) => {
//   const imgRef = useRef(null);
//   const previewCanvasRef = useRef(null);
//   const [crop, setCrop] = useState({ x: 0, y: 0 });
//   const [zoom, setZoom] = useState(1); // Initial zoom level
//   const [croppedAreaPixels, setCroppedAreaPixels] = useState();

//   const onCropComplete = (croppedArea, croppedAreaPixel) => {
//     setCroppedAreaPixels(croppedAreaPixel);
//   };

//   const handleDone = () => {
//     if (!previewCanvasRef.current) {
//       console.error("Canvas not found.");
//       return;
//     }

//     const canvas = previewCanvasRef.current;
//     const ctx = canvas.getContext("2d");

//     if (!ctx) {
//       console.error("Canvas context not found.");
//       return;
//     }

//     const { width, height, x, y } = croppedAreaPixels;

//     // Calculate new dimensions including white space and zoom
//     const newWidth = Math.max(width * zoom, 69); // Set to the desired minimum width
//     const newHeight = Math.max(height * zoom, 70); // Set to the desired minimum height

//     // Adjust canvas size to include white space
//     canvas.width = newWidth;
//     canvas.height = newHeight;

//     const imageUrl = openPOP_url; // Assuming the image URL is provided as openPOP_url

//     fetch(imageUrl)
//       .then((response) => response.blob())
//       .then((blob) => {
//         const image = new Image();
//         image.src = URL.createObjectURL(blob);
//         image.onload = () => {
//           // Draw the image on the canvas with white space
//           ctx.fillStyle = "white"; // Set the color to white
//           ctx.fillRect(0, 0, newWidth, newHeight); // Fill the canvas with white color
//           ctx.drawImage(
//             image,
//             x, // Use the cropped area x position
//             y, // Use the cropped area y position
//             width,
//             height,
//             // Adjust the destination coordinates to place the image at the cropped position
//             0, // Start from the left edge of the canvas
//             0, // Start from the top edge of the canvas
//             width * zoom, // Apply zoom factor to width
//             height * zoom // Apply zoom factor to height
//           );

//           const dataURL = canvas.toDataURL();
//           cropedImageSrc(dataURL);
//           closedPOP(null);
//         };
//       })
//       .catch((error) => {
//         console.error("Error loading image:", error);
//       });
//   };

//   const handleAutofit = () => {
//     if (!imgRef.current) return;

//     const imageWidth = imgRef.current.width;
//     const imageHeight = imgRef.current.height;
//     const canvasWidth = 69; // Desired canvas width
//     const canvasHeight = 70; // Desired canvas height

//     const scaleX = canvasWidth / imageWidth;
//     const scaleY = canvasHeight / imageHeight;
//     const scale = Math.min(scaleX, scaleY);

//     setZoom(scale);
// };



//   return (
//     <Popup
//       open={Boolean(openPOP_url)}
//       overflowY="auto"
//       height="500px"
//       padding="20px"
//       content={
//         <>
//           <Cropper
//             image={openPOP_url}
//             setImageRef={(ref) => (imgRef.current = ref.current)}
//             crop={crop}
//             zoom={zoom}
//             aspect={69 / 70}
//             onCropChange={setCrop}
//             onCropComplete={onCropComplete}
//             onZoomChange={setZoom}
//             cropSize={{ width: 69, height: 70 }}
//             showGrid={false}
//             maxWidth={69}
//             maxHeight={70}
//             zoomWithScroll={false}
//             restrictPosition={true}
//           />

//           {crop && (
//             <canvas
//               ref={previewCanvasRef}
//               style={{
//                 display: "none",
//                 border: "1px solid black",
//                 objectFit: "contain",
//                 width: 69,
//                 height: 70,
//               }}
//             />
//           )}
//            <Box
//             sx={{
//               mt: 2,
//               display: "flex",
//               justifyContent: "center",
//               background: "#fff",
//               zIndex: "9999999",
//               position: "relative",
//               width: "278px",
//               margin: "0 auto",
//               borderRadius: "66px",
//             }}
//             // style={{ position: "absolute", bottom: "-10%", left: "21%" }}
//           >
//             <Typography style={{ marginTop: "7px" }} variant="caption">
//               Zoom:
//             </Typography>
//             <Slider
//               value={zoom}
//               min={0.05}
//               max={3}
//               step={0.1}
//               onChange={(e) => {
//                 setZoom(e.target.value);
//               }}
//               // onChange={handleZoomChange}
//               style={{ width: 200, marginLeft: 10, overflow: "hidden" }}
//               className="zoom-range"
//             />
//           </Box>
//         </>
//       }
//       actions={
//         <>
//           <Box
//             sx={{ mt: 2 }}
//             style={{
//               textAlign: "center",
//               position: "absolute",
//               bottom: "2%",
//               right: "3%",
//             }}
//           >
//             <Button
//               size="large"
//               variant="contained"
//               color="primary"
//               style={{ background: "#15223F", marginRight: 10 }}
//               onClick={handleAutofit}
//             >
//               Autofit
//             </Button>
//             <Button
//               size="large"
//               variant="contained"
//               color="secondary"
//               style={{ background: "#F44336", marginRight: 10 }}
//               onClick={() => closedPOP(null)}
//             >
//               Cancel
//             </Button>
//             <Button
//               style={{ background: "#15223F" }}
//               variant="contained"
//               color="secondary"
//               size="large"
//               onClick={handleDone}
//             >
//               Done
//             </Button>
//           </Box>
//         </>
//       }
//     />
//   );
// };

// export default ImageCrop;

// React Easy Crop 

// import React, { useRef, useState } from "react";
// import Popup from "components/Popup";
// import { Button, Box, Typography, Slider } from "@mui/material";
// import Cropper from "react-easy-crop";
// import setCanvasPreview from "./setCanvasPreview";

// const ImageCrop = ({ openPOP_url, closedPOP, cropedImageSrc }) => {
//   const imgRef = useRef(null);
//   const previewCanvasRef = useRef(null);
//   const [crop, setCrop] = useState({ x: 0, y: 0 });
//   const [zoom, setZoom] = useState(1); // Initial zoom level
//   const [croppedAreaPixels, setCroppedAreaPixels] = useState();

//   const onCropComplete = (croppedArea, croppedAreaPixel) => {
//     setCroppedAreaPixels(croppedAreaPixel);
//   };

//   // const handleDone = () => {
//   //   if (!previewCanvasRef.current) {
//   //     console.error("Canvas not found.");
//   //     return;
//   //   }
  
//   //   const canvas = previewCanvasRef.current;
//   //   const ctx = canvas.getContext("2d");
  
//   //   if (!ctx) {
//   //     console.error("Canvas context not found.");
//   //     return;
//   //   }
  
//   //   const { width, height, x, y } = croppedAreaPixels;
  
//   //   // Calculate new dimensions including white space and zoom
//   //   const newWidth = Math.max(width * zoom, 69); // Set to the desired minimum width
//   //   const newHeight = Math.max(height * zoom, 70); // Set to the desired minimum height
  
//   //   // Adjust canvas size to include white space
//   //   canvas.width = newWidth;
//   //   canvas.height = newHeight;
  
//   //   const imageUrl = openPOP_url; // Assuming the image URL is provided as openPOP_url
//   //   // const imageUrl = openPOP_url; // Assuming the image URL is provided as openPOP_url
  
//   //   fetch(imageUrl)
//   //     .then((response) => response.blob())
//   //     .then((blob) => {
//   //       const image = new Image();
//   //       image.src = URL.createObjectURL(blob);
//   //       image.onload = () => {
//   //         // Draw the image on the canvas with white space
//   //         ctx.fillStyle = "white"; // Set the color to white
//   //         ctx.fillRect(0, 0, newWidth, newHeight); // Fill the canvas with white color
  
//   //         // Calculate position to center the image
//   //         const offsetX = Math.max((newWidth - width * zoom) / 2, 0);
//   //         const offsetY = Math.max((newHeight - height * zoom) / 2, 0);
  
//   //         ctx.drawImage(
//   //           image,
//   //           x, // Use the cropped area x position
//   //           y, // Use the cropped area y position
//   //           width,
//   //           height,
//   //           // Adjust the destination coordinates to place the image at the cropped position
//   //           offsetX, // Center horizontally
//   //           offsetY, // Center vertically
//   //           width * zoom, // Apply zoom factor to width
//   //           height * zoom // Apply zoom factor to height
//   //         );
  
//   //         const dataURL = canvas.toDataURL();
//   //         cropedImageSrc(dataURL);
//   //         closedPOP(null);
//   //       };
//   //     })
//   //     .catch((error) => {
//   //       console.error("Error loading image:", error);
//   //     });
//   // };

//   const handleDone = () => {
//     if (!previewCanvasRef.current) {
//       console.error("Canvas not found.");
//       return;
//     }
  
//     const canvas = previewCanvasRef.current;
//     const ctx = canvas.getContext("2d");
  
//     if (!ctx) {
//       console.error("Canvas context not found.");
//       return;
//     }
  
//     const { width, height, x, y } = croppedAreaPixels;
  
//     // Set the canvas size to the desired dimensions
//     const targetWidth = 69;
//     const targetHeight = 70;
  
//     // Adjust canvas size
//     canvas.width = targetWidth;
//     canvas.height = targetHeight;
  
//     const imageUrl = openPOP_url;
  
//     fetch(imageUrl)
//       .then((response) => response.blob())
//       .then((blob) => {
//         const image = new Image();
//         image.src = URL.createObjectURL(blob);
//         image.onload = () => {
//           // Clear the canvas and fill it with white
//           ctx.fillStyle = "white";
//           ctx.fillRect(0, 0, targetWidth, targetHeight);
  
//           // Calculate the new dimensions with zoom applied
//           const scaledWidth = width * zoom;
//           const scaledHeight = height * zoom;
  
//           // Calculate the position to center the image
//           const offsetX = Math.max((targetWidth - scaledWidth) / 2, 0);
//           const offsetY = Math.max((targetHeight - scaledHeight) / 2, 0);
  
//           ctx.drawImage(
//             image,
//             x, y, width, height,
//             offsetX, offsetY, scaledWidth, scaledHeight
//           );
  
//           const dataURL = canvas.toDataURL();
//           cropedImageSrc(dataURL);
//           closedPOP(null);
//         };
//       })
//       .catch((error) => {
//         console.error("Error loading image:", error);
//       });
//   };
  
  

//   return (
//     <Popup
//       open={Boolean(openPOP_url)}
//       overflowY="auto"
//       height="500px"
//       padding="20px"
//       content={
//         <>
//           <Cropper
//             image={openPOP_url}
//             setImageRef={(ref) => (imgRef.current = ref.current)}
//             crop={crop}
//             zoom={zoom}
//             aspect={69 / 70}
//             onCropChange={setCrop}
//             onCropComplete={onCropComplete}
//             onZoomChange={setZoom}
//             cropSize={{ width: 69, height: 70 }}
//             showGrid={false}
//             maxWidth={69}
//             maxHeight={70}
//             zoomWithScroll={false}
//             restrictPosition={true}
//           />

//           {crop && (
//             <canvas
//               ref={previewCanvasRef}
//               style={{
//                 display: "none",
//                 border: "1px solid black",
//                 objectFit: "contain",
//                 width: 69,
//                 height: 70,
//               }}
//             />
//           )}

//           <Box
//             sx={{
//               mt: 2,
//               display: "flex",
//               justifyContent: "center",
//               background: "#fff",
//               zIndex: "9999999",
//               position: "relative",
//               width: "278px",
//               margin: "0 auto",
//               borderRadius: "66px",
//             }}
//             // style={{ position: "absolute", bottom: "-10%", left: "21%" }}
//           >
//             <Typography style={{ marginTop: "7px" }} variant="caption">
//               Zoom:
//             </Typography>
//             <Slider
//               value={zoom}
//               min={0.05}
//               max={3}
//               step={0.1}
//               onChange={(e) => {
//                 setZoom(e.target.value);
//               }}
//               // onChange={handleZoomChange}
//               style={{ width: 200, marginLeft: 10, overflow: "hidden" }}
//               className="zoom-range"
//             />
//           </Box>
//         </>
//       }
//       actions={
//         <>
//           <Box
//             sx={{ mt: 2 }}
//             style={{
//               textAlign: "center",
//               position: "absolute",
//               bottom: "2%",
//               // left: "29%",
//               right: "3%",
//             }}
//           >
//             <Button
//               size="large"
//               variant="contained"
//               color="secondary"
//               style={{ background: "#F44336", marginRight: 10 }}
//               onClick={() => closedPOP(null)}
//             >
//               Cancel
//             </Button>
//             <Button
//               style={{ background: "#15223F" }}
//               variant="contained"
//               color="secondary"
//               size="large"
//               // onClick={() => {
//               //   setCanvasPreview(
//               //     imgRef.current,
//               //     previewCanvasRef.current,
//               //     croppedAreaPixels
//               //   );
//               //   cropedImageSrc(previewCanvasRef.current.toDataURL());
//               // }}
//               onClick={handleDone}
//             >
//               Done
//             </Button>
//           </Box>
//         </>
//       }
//     />
//   );
// };

// export default ImageCrop;

// Previous Code in Which Image is Taking the White space

import React, { useRef, useState } from "react";
import Popup from "components/Popup";
import { Button, Box, Typography, Slider } from "@mui/material";
import Cropper from "react-easy-crop";
import setCanvasPreview from "./setCanvasPreview";

const ImageCrop = ({ openPOP_url, closedPOP, cropedImageSrc }) => {
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1); // Initial zoom level
  const [croppedAreaPixels, setCroppedAreaPixels] = useState();

  const onCropComplete = (croppedArea, croppedAreaPixel) => {
    setCroppedAreaPixels(croppedAreaPixel);
  };

  const handleDone = () => {
    if (!previewCanvasRef.current) {
      console.error("Canvas not found.");
      return;
    }
  
    const canvas = previewCanvasRef.current;
    const ctx = canvas.getContext("2d");
  
    if (!ctx) {
      console.error("Canvas context not found.");
      return;
    }
  
    const { width, height } = croppedAreaPixels;
    canvas.width = 269;
    canvas.height = 270;
  
    const imageUrl = openPOP_url; // Assuming the image URL is provided as openPOP_url
  
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const image = new Image();
        image.src = URL.createObjectURL(blob);
  
        image.onload = () => {
          ctx.drawImage(
            image,
            croppedAreaPixels.x,
            croppedAreaPixels.y,
            croppedAreaPixels.width,
            croppedAreaPixels.height,
            0,
            0,
            269,
            270
          );
  
          const dataURL = canvas.toDataURL();
          cropedImageSrc(dataURL);
          closedPOP(null);
        };
      })
      .catch((error) => {
        console.error("Error loading image:", error);
      });
  };

    const handleAutofit = () => {
    if (!imgRef.current) return;

    const imageWidth = imgRef.current.width;
    const imageHeight = imgRef.current.height;
    const canvasWidth = 269; // Desired canvas width
    const canvasHeight = 270; // Desired canvas height

    const scaleX = canvasWidth / imageWidth;
    const scaleY = canvasHeight / imageHeight;
    const scale = Math.min(scaleX, scaleY);

    setZoom(scale);
};

  return (
    <Popup
      open={Boolean(openPOP_url)}
      overflowY="auto"
      height="500px"
      padding="20px"
      content={
        <>
          <Cropper
            image={openPOP_url}
            setImageRef={(ref) => (imgRef.current = ref.current)}
            crop={crop}
            zoom={zoom}
            aspect={269 / 270}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            cropSize={{ width: 269, height: 270 }}
            showGrid={false}
            maxWidth={269}
            maxHeight={270}
            zoomWithScroll={false}
            restrictPosition={true} 
          />

          {crop && (
            <canvas
              ref={previewCanvasRef}
              style={{
                display: "none",
                border: "1px solid black",
                objectFit: "contain",
                width: 269,
                height: 270,
              }}
            />
          )}

          <Box
            sx={{
              mt: 2,
              display: "flex",
              justifyContent: "center",
              background: "#fff",
              zIndex: "9999999",
              position: "relative",
              width: "278px",
              margin: "0 auto",
              borderRadius: "66px",
            }}
            // style={{ position: "absolute", bottom: "-10%", left: "21%" }}
          >
            <Typography style={{ marginTop: "7px" }} variant="caption">
              Zoom:
            </Typography>
            {/* <input
              type="range"
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e) => {
                setZoom(e.target.value);
              }}
              className="zoom-range"
            /> */}
            <Slider
              value={zoom}
              // min={0.4}
              min={0.1}
              max={3}
              step={0.1}
              onChange={(e) => {
                setZoom(e.target.value);
              }}
              // onChange={handleZoomChange}
              style={{ width: 200, marginLeft: 10, overflow: "hidden" }}
              className="zoom-range"
            />
          </Box>
        </>
      }
      actions={
        <>
          <Box
            sx={{ mt: 2 }}
            style={{
              textAlign: "center",
              position: "absolute",
              bottom: "2%",
              // left: "29%",
              right: "3%",
            }}
          >
              <Button
              size="large"
              variant="contained"
              color="primary"
              style={{ background: "#15223F", marginRight: 10 }}
              onClick={handleAutofit}
            >
              Autofit
            </Button>
            <Button
              size="large"
              variant="contained"
              color="secondary"
              style={{ background: "#F44336", marginRight: 10 }}
              onClick={() => closedPOP(null)}
            >
              Cancel
            </Button>
            <Button
              style={{ background: "#15223F" }}
              variant="contained"
              color="secondary"
              size="large"
              // onClick={() => {
              //   setCanvasPreview(
              //     imgRef.current,
              //     previewCanvasRef.current,
              //     croppedAreaPixels
              //   );
              //   cropedImageSrc(previewCanvasRef.current.toDataURL());
              // }}
              onClick={handleDone}
            >
              Done
            </Button>
          </Box>
        </>
      }
    />
  );
};

export default ImageCrop;

