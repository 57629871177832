import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grow from "@mui/material/Grow";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { IoMdClose } from "react-icons/io";
import './style.css';

const Popup = ({
  open,
  onClose,
  title,
  content,
  actions,
  overflowY,
  width,
  height,
  padding,
  closePopup,
  CloseHeight,
  CloseOverflow,TitleAlign
}) => {
  const style = {
    position: "absolute", 
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%) !important",
    width: width ? width : "500px",
    maxWidth: "auto",
    bgcolor: "white",
    borderRadius: 4,
    boxShadow: 24,
    p: 2,
    overflowY: overflowY,
    height: height,
    padding: padding,
    outline: 0, // Remove default outline
  };

  return (
    <Modal
      open={!!open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      keepMounted
    >
      <Grow in={open} mountOnEnter unmountOnExit>
        <Box className="scrollers" sx={style}>
          {closePopup && (
            <IoMdClose
              className="closeBTN"
              onClick={closePopup}
              fontSize={22}
              style={{
                color: "white",
                position: "absolute",
                right: "-1%",
                top: "-2%",
                zIndex: "99",
                background: "red",
                borderRadius: "50%",
                cursor: "pointer",
              }}
            />
          )}
          <div className="scrollers" style={{ height: CloseHeight, overflow: CloseOverflow, borderRadius: '0px' }}>
            {title && (
              <Typography
                id="modal-modal-title"
                variant="h3"
                component="div"
                sx={{ marginBottom: 2, textAlign:TitleAlign }}
              >
                {title}
              </Typography>
            )}
            <DialogContent style={{ padding: 0 }}>{content}</DialogContent>
            {actions && <DialogActions>{actions}</DialogActions>}
          </div>
        </Box>
      </Grow>
    </Modal>
  );
};

export default Popup;
