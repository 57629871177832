import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "api/API";
import Axios from "api/Axios";

export const updateUser = createAsyncThunk("updateUser", async (data) => {
  console.log(data, "updateUser");
  try {
    const response = await Axios.Filepost(
      `${API.Update_Profile}/${data.id}`,
      data.formData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (response.status === 200) {
      return response.data.data;
    }
  } catch (err) {
    console.log(err);
    return err;
  }
});

export const Data = createSlice({
  name: "Data",
  initialState: {
    loading: false,
    error: null,
    contentData: [],
    users: [],
    WallboardFilterData: [],
    SelectWallboardFilterDatas: [],
    PrePrintListingFilter: [],
    ViewAllFilter: [],
    PerksFilter:[],
    PostupFilter:[], 
    Email_List_Filter:[],
    Wallboard:[]
  },
  reducers: {
    contentData: (state, action) => {
      state.contentData = action.payload;
    },
    ProfileUser: (state, action) => {
      state.users = action.payload;
    },
    CreateWallboardFilterData: (state, action) => {
      state.WallboardFilterData = action.payload;
    },
    SelectWallboardFilterData: (state, action) => {
      state.SelectWallboardFilterDatas = action.payload;
    },
    PrePrintListingFilter: (state, action) => {
      state.PrePrintListingFilter = action.payload;
    },
    ViewAllFilter: (state, action) => {
      state.ViewAllFilter = action.payload;
    },
    PerksFilter: (state, action) => {
      state.PerksFilter = action.payload;
    },
    PostupFilter: (state, action) => {
      state.PostupFilter = action.payload;
    },
    Email_List_Filter: (state, action) => {
      state.Email_List_Filter = action.payload;
    },
    Wallboard: (state, action) => {
      state.Wallboard = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
        // state.users = state.users.map((item) =>
        // console.log(item, 'hello this is item')
        //   // item.id === action.payload.id ? action.payload : item
        // );
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      });
  },
});
// Action creators are generated for each case reducer function
export const {
  contentData,
  ProfileUser,
  CreateWallboardFilterData,
  SelectWallboardFilterData,
  PrePrintListingFilter,
  ViewAllFilter,
  PerksFilter,
  PostupFilter, 
  Email_List_Filter,
  Wallboard
} = Data.actions;

export default Data.reducer;
