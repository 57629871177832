import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  Pagination,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router";
import { PiFileCsvBold } from "react-icons/pi";
import { saveAs } from "file-saver";

// Components
import MainCard from "ui-component/cards/MainCard";
import Message from "components/Snackbar/Snackbar";
import Loading from "components/Loading";
import Popup from "components/Popup";
// import PrePrintForm from "./../PrePrintForm";
import { MENU_OPEN } from "store/actions";
import { PrePrintListingFilter, ViewAllFilter } from "store/Data";
import { requiredFields } from "../../PrePrint/Fields";
import { API } from "api/API";
import Axios from "api/Axios";
// import { monthss } from "../Fields";
import "../style.css";
import { GiConsoleController } from "react-icons/gi";

const validationSchema = Yup.object().shape({
  wallboards: Yup.array().of(
    Yup.object().shape({
      postOrder: Yup.mixed().required("Print Order is required"),
      year: Yup.mixed().required("Year is required"),
      month: Yup.mixed().required("Month is required"),
    })
  ),
});

const Data_collectionRWO = ({ ...others }) => {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [showData, setShowData] = useState([]);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [next, setNext] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [getValues, setValues] = useState({ month: "", year: "", printid: "" });
  const [printData, setPrintData] = useState();
  const [getFileNames, setFileNames] = useState([]);
  const [url, setURL] = useState(
    `${decodeURIComponent(location.search).substring(1)}`
  );
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    // severity: "",
  });

  const handleCloseSnackbar = () => {
    setSnackbar({
      open: false,
      message: "",
      severity: snackbar.severity,
    });
  };

  const dispatch = useDispatch();
  const params = useParams();

  const serialNumber = (page, index) => {
    return (page - 1) * rowsPerPage + index + 1;
  };

  const data = useSelector((state) => state.Data.PrePrintListingFilter);
  const viewAll = useSelector((state) => state.Data.ViewAllFilter);

  const handleChangePage = async (event, newPage) => {
    console.log("filename", data?.file_name, "viewall", viewAll?.ViewAll);
    try {
      setPage(newPage);
      if (data && data?.file_name) {
        // console.log("Filter");
        if(data?.file_name[0] === "View All"){
          await All_CSV_data(newPage);
        }
        else{
        await FilterData(newPage);
        }
      } else {
        if (location.search === "?View%20All") {
          // console.log("View All Data");
          await All_CSV_data(newPage);
        } else {
          await Get_All_listings(newPage);
        }
      }
    } catch (error) {
      console.log("Error in Pagination", error);
    }
  };

  const FilterData = async (page) => {
    try {
      setIsLoading(true);
      const response = await Axios.post(
        `${API.Post_Order_Filter}?page=${page}`,
        {
          file_name: data.file_name,
        }
      );
      if (response.status === 200) {
        setShowData(response.data.data);
        setNext(response?.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error, "Error while filter the data");
      setIsLoading(false);
      setShowData([]);
      setNext();
    }
  };

  const Get_All_listings = async (page) => {
    try {
      setIsLoading(true);
      const response = await Axios.post(`${API.CSV_LISTING}?page=${page}`, {
        pre_print_mergename_id:
          location.search === "?View%20All" ? undefined : params.id,
        post_order: params.po === "rwo" ? undefined : params.po,
        // batch_number: location?.state?.batch_number  ?? undefined
      });
      if (response.status === 200) {
        // console.log(response.data.data, "response");
        setShowData(response.data.data);
        setNext(response?.data);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err, "Error while getting Categories");
      setIsLoading(false);
      if (err.response.status === 404) {
        setShowData([]);
      }
    }
  };

  const GetRWOFiles = async () => {
    try {
      setIsLoading(true);
      const response = await Axios.get(API.RWO_Files);
      if (response.status === 200) {
        setFileNames(response.data.fileNames);
      }
    } catch (err) {
      console.log(err, "Error while getting Files");
      setIsLoading(false);
    }
  };

  const All_CSV_data = async (page) => {
    try {
      setIsLoading(true);
      const response = await Axios.get(`${API.View_CSV_All_Data}?page=${page}`);
      if (response.status === 200) {
        setShowData(response.data.data);
        setNext(response?.data);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err, "Error while getting Files");
      setIsLoading(false);
    }
  };

  const excludedFields = ["POSTER_TITLE", "MAILINGID", "MAILING_REFERENCE_NUMBER", "PACKAGE_ID"];


  const columns = [
    { id: "sno", label: "#" },
    // ...requiredFields.map((item) => ({ id: item, label: item })),
    ...requiredFields
    .filter((field) => !excludedFields.includes(field))
    .map((item) => ({ id: item, label: item })),
  ];

  const years = ["2021", "2022", "2023", "2024"];

  const handleCSV = async () => {
    const APIURL =
      !data || Object.keys(data)?.length === 0
        ? API.Export_CSV_Data
        : `${API.Export_CSV_Data}?` +
          `${data.post_order ? `post_order=${data.post_order}&` : ""}` +
          `${data.year ? `year=${data.year}&` : ""}` +
          `${data.month ? `month=${data.month}&` : ""}` +
          `${data.file_name ? `file=${data.file_name}` : ""}`;
    try {
      setIsLoading(true);
      const response = await Axios.get(APIURL);
      if (response.status === 200) {
        setIsLoading(false);
        const Loc_fileName = `${decodeURIComponent(location.search).substring(
          1
        )}.csv`;
        const fileName =  data.file_name ? `${data.file_name}.csv` : Loc_fileName
        saveAs(new Blob([response.data]), fileName);
      }
    } catch (err) {
      console.log(err, "Error while getting Categories");
      setIsLoading(false);
    }
  };

  const pathname = "/prePrint/listing/2/rwo?View%20All";

  useEffect(() => {
    GetRWOFiles();
    if (pathname === location.pathname + location.search) {
      All_CSV_data(page);
      const data = { ViewAll: true };
      dispatch(ViewAllFilter(data));
    } else {
      Get_All_listings(page);
    }
    dispatch({ type: MENU_OPEN, id: "datacollection" });
  }, []);

  return (
    <MainCard
      title="View RWO"
      buttontitle1="Export CSV"
      approveColor="#15223f"
      startIcon={<PiFileCsvBold />}
      approve={handleCSV}
      disabled={
        (location.search === "?View%20All" && true) ||
        (location.search === "" && true)
      }
      display={
        (location.search === "?View%20All" && "none") ||
        (location.search === "" && "none")
      }
    >
      <Formik
        style={{ marginBottom: 6 }}
        initialValues={{
          postOrder: "",
          year: "",
          month: "",
          fileName:
            location.search !== ""
              ? [`${decodeURIComponent(location.search).substring(1)}`]
              : "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            if(!values.fileName){
              setSnackbar({
                open:true, 
                message: "Please select the file",
                severity:'error'
              })
            }
            else{
            setIsLoading(true);
            const data = {
              // post_order: !values.postOrder ? undefined : values.postOrder,
              // year: !values.year ? undefined : values.year,
              // month: !values.month ? undefined : values.month,
              file_name: !values.fileName ? undefined : values.fileName,
            };
            dispatch(PrePrintListingFilter(data));
            dispatch(ViewAllFilter([]));
            if(values.fileName[0] === 'View All'){
              await All_CSV_data(page);
            }
            else{
            const response = await Axios.post(
              `${API.Post_Order_Filter}?page=${page}`,
              data
            );
            if (response.status === 200) {
              setShowData(response.data.data);
              setNext(response?.data);
              setIsLoading(false);
            }}}
          } catch (error) {
            console.log(error, "Error while filter the data");
            setIsLoading(false);
            setShowData([]);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          setFieldValue,
          setYear,
          resetForm,
          values,
        }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            {...others}
            autoComplete="off"
          >
            <div className="xyz">
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 1 }}
                sx={{ alignItems: "center", marginBottom: 5 }}
              >
                <Grid item xs={0.8}>
                  <Typography variant="subtitle1">Filter by:</Typography>
                </Grid>

                {/* Select File */}
                <Grid item xs={2}>
                  <FormControl
                    fullWidth
                    // error={Boolean(touched.year && errors.year)}
                    // sx={{ ...theme.typography.customInput }}
                  >
                    <Autocomplete
                      autoHighlight
                      options={getFileNames || []}
                      getOptionLabel={(option) => option || ""}
                      // key={(option) => option.id}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select File"
                          variant="outlined"
                        />
                      )}
                      name="fileName"
                      value={
                        values.fileName
                          ? getFileNames.find(
                              (item) => item === values.fileName
                            ) || location.search !== ""
                            ? url
                            : ""
                          : ""
                      }
                      onChange={(_, newValue) => {
                        setFieldValue(`fileName`, newValue ? newValue : "");
                        setURL(newValue ? newValue : "");
                      }}
                      noOptionsText="No Results Found"
                    />
                    {touched.fileName && errors.fileName && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-year"
                      >
                        {errors.fileName}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                {/* Buttons */}
                <Grid item xs={3} style={{ display: "flex", gap: "10px" }}>
                  <Button
                    disableElevation
                    disabled={isSubmitting}
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    style={{
                      background: "#15223F",
                      padding: "12px 20px",
                      borderRadius: "8px",
                      color: isSubmitting && "white",
                    }}
                    // onClick={() => {
                    //   dispatch(ViewAllFilter(undefined));
                    // }}
                  >
                    Filter
                  </Button>
                  <Button
                    size="large"
                    variant="contained"
                    color="secondary"
                    style={{
                      background: "#C62828",
                      padding: "12px 20px",
                      borderRadius: "8px",
                      color: isSubmitting && "white",
                    }}
                    onClick={async () => {
                      setFieldValue(`fileName`, "");
                      setPage(1);
                      dispatch(PrePrintListingFilter(undefined));
                      dispatch(ViewAllFilter(undefined));
                      await Get_All_listings(1);
                    }}
                  >
                    Clear
                  </Button>
                  {/* <Button
                    size="large"
                    variant="contained"
                    color="secondary"
                    style={{
                      background: "#15223F",
                      padding: "12px 10px",
                      borderRadius: "8px",
                      color: "white",
                    }}
                    onClick={async () => {
                      await All_CSV_data(page);
                      const data = { ViewAll: true };
                      dispatch(ViewAllFilter(data));
                    }}
                  >
                    View all
                  </Button> */}
                </Grid>
              </Grid>
            </div>
            {/* <Popup
              title="Please enter the details"
              open={open}
              overflowY="auto"
              // height="365px"
              content={
                <>
                  <PrePrintForm
                    setIsLoading={setIsLoading}
                    close={() => setOpen(false)}
                    value={values}
                    setSnackbar={setSnackbar}
                    printData={printData}
                  />
                </>
              }
            /> */}
          </form>
        )}
      </Formik>

      <TableContainer
        className="chips"
        component={Paper}
        style={{ border: "1px solid black" }}
      >
        <Table>
          <TableHead>
            <TableRow style={{ background: "#364152", border: "none" }}>
              {columns.map((column) => (
                <TableCell
                  style={{ textAlign: "center", color: "#fff", border: "none" }}
                  className="headings"
                  // style={{ color: "#fff" }}
                  key={column.id}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {showData && showData.length > 0 ? (
              showData.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell
                      style={{ textAlign: "center" }}
                      className="common"
                      // padding="checkbox"
                    >
                      {serialNumber(page, index)}
                    </TableCell>

                    <TableCell className="common">{item?.GUID}</TableCell>
                    <TableCell className="common">{item?.ENTITY}</TableCell>
                    <TableCell className="common">{item?.ISSUE}</TableCell>
                    <TableCell className="common">{item?.RWODATE}</TableCell>
                    <TableCell className="common">{item?.LID}</TableCell>
                    <TableCell className="common">
                      {item?.CONTACT_NAME}
                    </TableCell>
                    <TableCell className="common">{item?.TITLE}</TableCell>
                    <TableCell className="common">
                      {item?.CONTACT_SUFFIX}
                    </TableCell>
                    <TableCell className="common">{item?.COMPANY}</TableCell>
                    <TableCell className="common">{item?.DEPARTMENT}</TableCell>
                    <TableCell className="common">{item?.ADDRESS1}</TableCell>
                    <TableCell className="common">{item?.ADDRESS2}</TableCell>
                    <TableCell className="common">
                      {item?.["STE/FLOOR"]}
                    </TableCell>
                    <TableCell className="common">{item?.CITY}</TableCell>
                    <TableCell className="common">{item?.STATE}</TableCell>
                    <TableCell className="common">{item?.ZIP}</TableCell>
                    <TableCell className="common">{item?.PHONE}</TableCell>
                    <TableCell className="common">
                      {item?.POSTER_CODE_1}
                    </TableCell>
                    <TableCell className="common">
                      {item?.POSTER_QTY_1}
                    </TableCell>
                    <TableCell className="common">
                      {item?.POSTER_CODE_2}
                    </TableCell>
                    <TableCell className="common">
                      {item?.POSTER_QTY_2}
                    </TableCell>
                    <TableCell className="common">
                      {item?.POSTER_CODE_3}
                    </TableCell>
                    <TableCell className="common">
                      {item?.POSTER_QTY_3}
                    </TableCell>
                    <TableCell className="common">
                      {item?.TOTALPOSTERS}
                    </TableCell>
                    <TableCell className="common">
                      {item?.AFFIX_BROCHURE_CODE_1}
                    </TableCell>
                    <TableCell className="common">
                      {item?.AFFIX_BROCHURE_QTY_1}
                    </TableCell>
                    <TableCell className="common">
                      {item?.AFFIX_BROCHURE_CODE_2}
                    </TableCell>
                    <TableCell className="common">
                      {item?.AFFIX_BROCHURE_QTY_2}
                    </TableCell>
                    <TableCell className="common">
                      {item?.AFFIX_BROCHURE_CODE_3}
                    </TableCell>
                    <TableCell className="common">
                      {item?.AFFIX_BROCHURE_QTY_3}
                    </TableCell>
                    <TableCell className="common">
                      {item?.BROCHURE_CODE_1}
                    </TableCell>
                    <TableCell className="common">
                      {item?.BROCHURE_QTY_1}
                    </TableCell>
                    <TableCell className="common">{item?.LETTER_1}</TableCell>
                    <TableCell className="common">
                      {item?.LETTER_QTY_1}
                    </TableCell>
                    <TableCell className="common">{item?.LETTER_2}</TableCell>
                    <TableCell className="common">
                      {item?.LETTER_QTY_2}
                    </TableCell>
                    <TableCell className="common">{item?.LETTER_3}</TableCell>
                    <TableCell className="common">
                      {item?.LETTER_QTY_3}
                    </TableCell>
                    <TableCell className="common">{item?.PACKAGING}</TableCell>
                    <TableCell className="common">
                      {item?.BROCHURE_CODE_2}
                    </TableCell>
                    <TableCell className="common">
                      {item?.BROCHURE_QTY_2}
                    </TableCell>
                    <TableCell className="common">
                      {item?.BROCHURE_CODE_3}
                    </TableCell>
                    <TableCell className="common">
                      {item?.BROCHURE_QTY_3}
                    </TableCell>
                    <TableCell className="common">{item?.ADHESIVE_1}</TableCell>
                    <TableCell className="common">
                      {item?.ADHESIVE_QTY_1}
                    </TableCell>
                    <TableCell className="common">{item?.ADHESIVE_2}</TableCell>
                    <TableCell className="common">
                      {item?.ADHESIVE_QTY_2}
                    </TableCell>
                    <TableCell className="common">{item?.ADHESIVE_3}</TableCell>
                    <TableCell className="common">
                      {item?.ADHESIVE_QTY_3}
                    </TableCell>
                    <TableCell className="common">
                      {item?.VINYL_ENV_1}
                    </TableCell>
                    <TableCell className="common">
                      {item?.VINYL_ENV_QTY_1}
                    </TableCell>
                    <TableCell className="common">
                      {item?.VINYL_ENV_2}
                    </TableCell>
                    <TableCell className="common">
                      {item?.VINYL_ENV_QTY_2}
                    </TableCell>
                    <TableCell className="common">
                      {item?.VINYL_ENV_3}
                    </TableCell>
                    <TableCell className="common">
                      {item?.VINYL_ENV_QTY_3}
                    </TableCell>
                    <TableCell className="common">{item?.ADD_1}</TableCell>
                    <TableCell className="common">{item?.ADD_2}</TableCell>
                    <TableCell className="common">{item?.ADD_3}</TableCell>
                    <TableCell className="common">
                      {item?.RWOLISTNAME}
                    </TableCell>
                    <TableCell className="common">
                      {item?.ADDRESSVERIFIED}
                    </TableCell>
                    <TableCell className="common">{item?.Thickness}</TableCell>
                    <TableCell className="common">
                      {item?.PackagingWeight}
                    </TableCell>
                    <TableCell className="common">{item?.PIWeight}</TableCell>
                    <TableCell className="common">
                      {item?.VinylWeight}
                    </TableCell>
                    <TableCell className="common">
                      {item?.AdhesiveWeight}
                    </TableCell>
                    <TableCell className="common">
                      {item?.PosterWeight}
                    </TableCell>
                    <TableCell className="common">
                      {item?.LetterWeight}
                    </TableCell>

                    <TableCell className="common">
                      {item?.TotalWeight}
                    </TableCell>

                    <TableCell className="common">{item?.MailClass}</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell style={{ textAlign: "center" }} colSpan={10}>
                  Record Not Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* <Popup
        title="Please enter the details"
        open={open1}
        overflowY="auto"
        // height="365px"
        content={
          <>
            <PrePrintForm
              setIsLoading={setIsLoading}
              close={() => setOpen1(false)}
              value={getValues}
              setSnackbar={setSnackbar}
              printData={printData}
            />
          </>
        }
      /> */}

      {showData?.length > 0 && next?.totalCount > 10 && (
        <Pagination
          count={next?.totalPages}
          defaultPage={1}
          page={page}
          onChange={handleChangePage}
          siblingCount={1}
          boundaryCount={1}
          color="primary"
          className="paging"
          sx={{
            button: { mt: 2 },
            width: "100%",
            display: "flex",
            justifyContent: { xs: "center", md: "flex-end" },
          }}
        />
      )}

      <Message snackbar={snackbar} handleCloseSnackbar={handleCloseSnackbar} />
      <Loading isLoading={isLoading} height={80} width={80} color="#15223F" />
    </MainCard>
  );
};

export default Data_collectionRWO;
