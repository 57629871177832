import React, { useState } from "react";
import { Button } from "@mui/material";
import MainCard from "ui-component/cards/MainCard";

// Components
import Loading from "components/Loading";
import Popup from "components/Popup";
import Message from "components/Snackbar/Snackbar";
import CreatePO from "./CreatePO";
import "./style.css";
import ListingData from "./ListingData";
import { useEffect } from "react";
import { MENU_OPEN } from "store/actions";
import { useDispatch } from "react-redux";
import Axios from "api/Axios";
import { API } from "api/API";

const DataCollection = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [open, setopen] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    // severity: "",
  });
  const [childRowExpansion, setChildRowExpansion] = useState([].fill(false));
  const [rowExpansion, setRowExpansion] = useState([].fill(false));
  const [showData, setShowData] = useState([]);
  const [next, setNext] = useState();
  const handleCloseSnackbar = () => {
    setSnackbar({
      open: false,
      message: "",
      severity: snackbar.severity,
    });
  };

  const Get_All_listings = async (page) => {
    try {
      setIsLoading(true);
      const response = await Axios.get(`${API.PrePrintListings}?page=${page}`);
      if (response.status === 200) {
        setShowData(response.data.data);
        setNext(response?.data);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err, "Error while getting Listings");
      setShowData([]);
      setIsLoading(false);
    }
  };

  const RowClose =()=>{
    setChildRowExpansion([].fill(false));
    setRowExpansion(Array(showData.length).fill(false))
  }

  useEffect(() => {
    dispatch({ type: MENU_OPEN, id: "datacollection" });
    RowClose();
  }, []);

  return (
    <MainCard title="Data Collection">
      <Button
        size="large"
        variant="contained"
        style={{ background: "#15223F", marginBottom: 20 }}
        onClick={() => setopen(true)}
      >
        Create PO
      </Button>

      <ListingData
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setSnackbar={setSnackbar}
        Get_All_listings={Get_All_listings}
        next={next}
        setNext={setNext}
        setShowData={setShowData}
        showData={showData}
        childRowExpansion={childRowExpansion}
        setChildRowExpansion={setChildRowExpansion}
        rowExpansion={rowExpansion}
        setRowExpansion={setRowExpansion}
        // RowClose={() =>
        //   setRowExpansion(Array(showData.length).fill(false))
        // }
        RowClose={RowClose}
      />

      <Popup
        title="Create PO"
        open={open}
        overflowY="auto"
        height="500px"
        CloseOverflow='hidden'
        content={
          <>
            <CreatePO
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              close={() => setopen(false)}
              setSnackbar={setSnackbar}
              Get_All_listings={Get_All_listings}
              // setChildRowExpansion={setChildRowExpansion}
              // setRowExpansion={setRowExpansion}
              RowClose={RowClose}
            />
          </>
        }
      />

      <Loading isLoading={isLoading} height={80} width={80} color="#15223F" />
      <Message snackbar={snackbar} handleCloseSnackbar={handleCloseSnackbar} />
    </MainCard>
  );
};

export default DataCollection;
