import React, { useState, useEffect } from 'react';
import { Formik, Field, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  FormHelperText,
  Autocomplete,
  TextField,
} from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import { MdOutlineRemoveCircle } from 'react-icons/md';
import { IoMdAdd } from 'react-icons/io';
import { useTheme } from '@emotion/react';
import { months } from 'utils/Fields';

import './style.css';
import Axios from 'api/Axios';
import { API } from 'api/API';
import moment from 'moment';
import MonthYearPicker from 'components/DatePicker/MonthYearPicker';
import { FaCalendarAlt } from 'react-icons/fa';

const currentDate = new Date();
const currentYear = currentDate.getFullYear();

const currentMonth = String(currentDate.getMonth() + 2).padStart(2, '0');

const nextMonthDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
const nextMonthName = nextMonthDate.toLocaleString('default', {
  month: 'long',
});

function getMonthStringFromName(monthName) {
  // Parse the month name using moment
  const parsedMonth = moment(monthName, 'MMMM');
  // Format the parsed month as 'MM' (two-digit month number)
  const monthString = parsedMonth.format('MM');
  return monthString;
}

const validationSchema = Yup.object().shape({
  wallboards: Yup.array().of(
    Yup.object().shape({
      // wallboardname: Yup.string().required("Wallboard Name is required"),
      wallboardname: Yup.string().trim().required('Wallboard Name is required'),
      template_id: Yup.string().required('Please select Template'),
      network_type: Yup.string().required('Edition Name is required'),
      theme_name: Yup.string().required('Theme is required'),
      // select_year: Yup.string().required("Month and Year is required"),
      select_year: Yup.string()
        .required('Month and Year is required')
        .test('is-valid-month-year', 'Invalid Month and Year format', (value) => {
          return /^[0-9]{4}-(0[1-9]|1[0-2])$/.test(value);
        }),
    })
  ),
});

const AddRemoveForm = ({
  close,
  setSnackbar,
  Get_All_Wallboards,
  setIsLoading,
  page,
  apiCall,
  copyWallboardDetails,
  setDeleteSelected,
  setSelected,
}) => {
  const initialValues = {
    wallboards: copyWallboardDetails.wallboardDataList.map((item) => ({
      wallboardname: item.wallboardname || '',
      template_id: item.template_id || '',
      network_type: item.network_type || '',
      theme_name: item.template_theme || '',
      month: item.month || nextMonthName,
      select_year: item.year
        ? `${item.year}-${getMonthStringFromName(item.month)}`
        : `${currentYear}-${currentMonth}`,
      year: item.year?.toString() || currentYear.toString(),
      templateName: item.templateName || '',
      templateThemeName: item.templateThemeName || '',
    })),
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [addMore, setAddMore] = useState(false);
  const [network_Options, setNetworkOptions] = useState([]);
  const [template, setTemplate] = useState([]);
  const [themeData, setTheme] = useState([]);

  return (
    <Formik
      initialValues={formValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        // console.log(values, "hi these are");
        const data = values.wallboards.map((item) => {
          return {
            template_id: item.template_id,
            wallboardname: item.wallboardname,
            network_type: item.network_type,
            template_theme: item.theme_name,
            month: item.month,
            year: parseInt(item.year, 10),
            type: 'copy',
          };
        });
        setSubmitting(false);
        try {
          setIsLoading(true);
          const response = await Axios.post(API.Create_Wallboard, data);
          if (response.status === 201) {
            Get_All_Wallboards(page);
            // console.log(response.data, "submit data");
            setSnackbar({
              open: true,
              message: response.data.message,
              severity: 'success',
            });
            setDeleteSelected([]);
            setSelected([]);
            close();
            setIsLoading(false);
          }
        } catch (err) {
          console.log(err, 'Error while creating Wallboard');
          setIsLoading(false);
        }
      }}
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <form onSubmit={handleSubmit} autoComplete="off">
          <FieldArray name="wallboards">
            {({ push, remove }) => (
              <div>
                {values.wallboards.map((_, index) => (
                  <div className="wallboard_form" key={index}>
                    <div>
                      <FormControl fullWidth variant="outlined">
                        <TextField
                          type="text"
                          name={`wallboards[${index}].wallboardname`}
                          value={values.wallboards[index].wallboardname}
                          onChange={(e) =>
                            setFieldValue(`wallboards[${index}].wallboardname`, e.target.value)
                          }
                          variant="outlined"
                          label="Wallboard Name"
                        />
                      </FormControl>
                      <ErrorMessage
                        name={`wallboards[${index}].wallboardname`}
                        component="div"
                        className="error"
                        style={{ color: 'red' }}
                      />
                    </div>

                    <div>
                      <FormControl style={{ marginTop: 10 }} fullWidth className="Select_template">
                        <TextField
                          type="text"
                          name={`wallboards[${index}].templateName`}
                          value={values.wallboards[index].templateName}
                          disabled="true"
                          variant="outlined"
                          label="Select Template"
                        />
                      </FormControl>
                      <ErrorMessage
                        name={`wallboards[${index}].template_id`}
                        component="div"
                        className="error"
                        style={{ color: 'red' }}
                      />
                    </div>

                    <div>
                      <FormControl style={{ marginTop: 10 }} fullWidth>
                        <TextField
                          type="text"
                          name={`wallboards[${index}].templateThemeName`}
                          value={values.wallboards[index].templateThemeName}
                          disabled="true"
                          //   onChange={(e) =>
                          //     setFieldValue(
                          //       `wallboards[${index}].templateName`,
                          //       e.target.value
                          //     )
                          //   }
                          variant="outlined"
                          label="Select Theme"
                        />
                      </FormControl>
                      <ErrorMessage
                        name={`wallboards[${index}].theme_name`}
                        component="div"
                        className="error"
                        style={{ color: 'red' }}
                      />
                    </div>

                    <div>
                      <FormControl
                        style={{ marginTop: 10 }}
                        fullWidth
                        // className="select_template"
                      >
                        <TextField
                          type="text"
                          name={`wallboards[${index}].network_type`}
                          value={values.wallboards[index].network_type}
                          disabled="true"
                          //   onChange={(e) =>
                          //     setFieldValue(
                          //       `wallboards[${index}].templateName`,
                          //       e.target.value
                          //     )
                          //   }
                          variant="outlined"
                          label="Edition Name"
                        />
                      </FormControl>
                      <ErrorMessage
                        name={`wallboards[${index}].network_type`}
                        component="div"
                        className="error"
                        style={{ color: 'red' }}
                      />
                    </div>
                    {/* <div>
                      <FormControl
                        style={{ marginTop: 12, marginBottom: 0 }}
                        fullWidth
                        className="select_year"
                      >
                        <TextField
                          label="Select Month and Year"
                          type="month"
                          name="select_year"
                          InputLabelProps={{
                            shrink: true,
                          }}
                          variant="outlined"
                          className="dating"
                          id={`datepickers-${index}`} // Make the id unique for each index
                          value={values.wallboards[index].select_year}
                          onChange={(e) => {
                            const value = e.target.value;
                            const node = document.getElementById(
                              `datepickers-${index}`
                            );
                            if (!value) {
                              setFieldValue(
                                `wallboards[${index}].select_year`,
                                e.target.value
                              );
                              node.style.color = "transparent";
                            } else {
                              const [select_year, month] = value.split("-");
                              const selectedYear = parseInt(select_year, 10);
                              if (selectedYear < currentYear) {
                                e.target.value = "";
                                setFieldValue(
                                  `wallboards[${index}].select_year`,
                                  e.target.value
                                );
                                node.style.color = "transparent";
                                setSnackbar({
                                  open: true,
                                  message:
                                    "Please Select the current and next year only",
                                  severity: "error",
                                });
                              } else {
                                node.style.color = "black";
                                const monthName = new Date(
                                  `${select_year}-${month}-01`
                                ).toLocaleString("default", { month: "long" });
                                // console.log('year:', value);
                                setFieldValue(
                                  `wallboards[${index}].month`,
                                  monthName ? monthName : ""
                                );
                                setFieldValue(
                                  `wallboards[${index}].year`,
                                  select_year ? select_year : ""
                                );
                                setFieldValue(
                                  `wallboards[${index}].select_year`,
                                  e.target.value
                                );
                              }
                            }
                          }}
                        />
                        {!values.wallboards[index].select_year && (
                          <span
                            style={{ position: "absolute", top: 18, left: 15 }}
                          >
                            MM, YYYY
                          </span>
                        )}
                      </FormControl>
                      <ErrorMessage
                        name={`wallboards[${index}].select_year`}
                        component="div"
                        className="error"
                        style={{ color: "red" }}
                      />
                    </div> */}

                    <div>
                      <FormControl
                        style={{ marginTop: 12, marginBottom: 0 }}
                        fullWidth
                        className="select_year"
                      >
                        <MonthYearPicker
                          placeholder="Select Month and Year"
                          value={values.wallboards[index].select_year}
                          onChange={(date) => {
                            const formattedDate = date ? date.toISOString().slice(0, 7) : '';
                            setFieldValue(`wallboards[${index}].select_year`, formattedDate);
                            if (date) {
                              const monthName = date.toLocaleString('default', {
                                month: 'long',
                              });
                              const year = date.getFullYear().toString();
                              setFieldValue(`wallboards[${index}].month`, monthName);
                              setFieldValue(`wallboards[${index}].year`, year);
                            } else {
                              setFieldValue(`wallboards[${index}].month`, '');
                              setFieldValue(`wallboards[${index}].year`, '');
                            }
                          }}
                        />
                        <FaCalendarAlt
                          style={{
                            position: 'absolute',
                            right: '10px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            pointerEvents: 'none',
                            color: '#999',
                          }}
                        />
                      </FormControl>
                      <ErrorMessage
                        name={`wallboards[${index}].select_year`}
                        component="div"
                        className="error"
                        style={{ color: 'red' }}
                      />
                    </div>

                    {/* {index > 0 && ( // Conditionally render the Remove button
                      <div>
                        <Button
                          size="small"
                          variant="contained"
                          style={{
                            background: "#C62828",
                            marginBottom: 10,
                            marginTop: 9,
                          }}
                          startIcon={<MdOutlineRemoveCircle />}
                          onClick={() => remove(index)}
                        >
                          Remove
                        </Button>
                      </div>
                    )} */}
                  </div>
                ))}
                {/* <div>
                  <div>
                    <Button
                      size="small"
                      variant="contained"
                      style={{ background: "#15223F", marginBottom: 10 }}
                      startIcon={<IoMdAdd />}
                      onClick={() => {
                        // Check if all existing fields are filled before adding a new one
                        const allFieldsFilled = values.wallboards.every(
                          (wallboard) =>
                            wallboard.wallboardname &&
                            wallboard.template_id &&
                            wallboard.network_type &&
                            wallboard.theme_name &&
                            wallboard.select_year &&
                            wallboard.theme_name
                        );

                        if (allFieldsFilled) {
                          push({
                            wallboardname: "",
                            template_id: "",
                            network_type: "",
                            select_year: `${currentYear}-${currentMonth}` || "",
                            theme_name: "",
                          });
                          setAddMore(false);
                        } else {
                          setAddMore(true);
                        }
                      }}
                    >
                      Add More
                    </Button>
                    {addMore && (
                      <FormHelperText style={{ marginBottom: "10px" }} error>
                        Please fill in all fields before adding more.
                      </FormHelperText>
                    )}
                  </div>
                </div> */}
              </div>
            )}
          </FieldArray>
          <div style={{ textAlign: 'center' }}>
            <Button
              size="large"
              variant="contained"
              style={{ background: '#C62828', marginRight: '10px' }}
              onClick={close}
            >
              Close
            </Button>
            <Button
              size="large"
              type="submit"
              variant="contained"
              style={{ background: '#1d213e', marginRight: '15px' }}
            >
              Submit
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default AddRemoveForm;
