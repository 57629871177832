import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Paper,
} from "@mui/material/";

// Components
import MainCard from "ui-component/cards/MainCard";
import { fields } from "../Fields";
import Axios from "api/Axios";
import { API } from "api/API";
import Message from "components/Snackbar/Snackbar";
import Loading from "components/Loading";
import { FaEdit } from "react-icons/fa";
import Popup from "components/Popup";
import EditQRDetails from "./EditQRDetails";

const ViewAssets = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showData, setShowData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [addQR, setAddQR] = useState(false);
  const [EditQRFields, setEditQRfields] = useState({});

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    // severity: "",
  });
  const location = useLocation();
  const data = location.state;

  const handleCloseSnackbar = () => {
    setSnackbar({
      open: false,
      message: "",
      severity: snackbar.severity,
    });
  };

  const columns = [
    { id: "sno", label: "#" },
    ...fields.map((item) => ({ id: item, label: item })),
    { id: "actions", label: "Actions" },
  ];

  const serialNumber = (page, index) => {
    return (page - 1) * rowsPerPage + index + 1;
  };

  const getData = async () => {
    const datas = {
      merged_name: data?.uploadAssetsData.merged_name,
      post_order: data?.uploadAssetsData.post_order,
      post_code: data.post_code,
      batch_number: data.uploadAssetsData.batch_number,
      file_name: data.uploadAssetsData.file_name,
    };
    try {
      setIsLoading(true);
      const response = await Axios.post(API.GetQRDataListings, datas);
      if (response.status === 200) {
        setIsLoading(false);
        setShowData(response.data.data);
      }
    } catch (err) {
      console.log(err, "Error while getting Categories");
      setIsLoading(false);
    }
  };

  const editActions = (TempName,  QR1, QR5_3COL , QR4_POLL, item) => {
    setAddQR(true);
    setEditQRfields({
      Template_Name: TempName,
      merged_name: data?.uploadAssetsData.merged_name,
      post_code: data.post_code,
      QR1:QR1,
      QR5_3COL:QR5_3COL,
      QR4_POLL:QR4_POLL, 
      Article_1: item.Article_1, 
      Article_2: item.Article_2, 
      Article_3: item.Article_3, 
      Article_6_Column_4_A3: item.Article_6_Column_4_A3,
      Article_7_Column_4_A4: item.Article_7_Column_4_A4
    });
  };

  useEffect(() => {
    getData();
    if (location && location.state) {
      // Access the data passed from the previous page
      const data = location.state;
    }
  }, [location]);

  return (
    <MainCard title="View Assets">
      <TableContainer
        className="chips"
        component={Paper}
        style={{ border: "1px solid black" }}
      >
        <Table>
          <TableHead>
            <TableRow style={{ background: "#364152", border: "none" }}>
              {columns.map((column) => (
                <TableCell
                  style={{ textAlign: "center", color: "#fff", border: "none" }}
                  className="headings"
                  // style={{ color: "#fff" }}
                  key={column.id}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {showData && showData.length > 0 ? (
              showData.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell
                      style={{ textAlign: "center" }}
                      className="common"
                      // padding="checkbox"
                    >
                      {serialNumber(page, index)}
                    </TableCell>
                    <TableCell className="common">
                      {item?.POSTER_CODE}
                    </TableCell>
                    <TableCell className="common">
                      {item?.Template_Name}
                    </TableCell>
                    <TableCell className="common">{item?.QR1}</TableCell>
                    <TableCell className="common">{item?.QR2_ENL}</TableCell>
                    <TableCell className="common">{item?.QR3_POD}</TableCell>
                    <TableCell className="common">{item?.QR4_POLL}</TableCell>
                    <TableCell className="common">{item?.QR5_3COL}</TableCell>
                    <TableCell className="common">{item?.QR6_Perks}</TableCell>
                    <TableCell className="common">{item?.QR7}</TableCell>
                    <TableCell className="common">{item?.QR8}</TableCell>
                    <TableCell className="common">{item?.QR9}</TableCell>
                    <TableCell className="common">{item?.QR10}</TableCell>
                    <TableCell className="common">{item?.Article_1}</TableCell>
                    <TableCell className="common">{item?.Article_2}</TableCell>
                    <TableCell className="common">{item?.Article_3}</TableCell>
                    <TableCell className="common">{item?.Article_6_Column_4_A3}</TableCell>
                    <TableCell className="common">{item?.Article_7_Column_4_A4}</TableCell>
                    <TableCell className="common">{item?.PDF_Manual}</TableCell>
                    <TableCell className="common">{item?.PDF_AI}</TableCell>
                    <TableCell className="common">{item?.PDF_AD_1}</TableCell>
                    <TableCell className="common">{item?.PDF_AD_2}</TableCell>
                    <TableCell className="common">{item?.PDF_URL}</TableCell>
                    <TableCell
                      className="common"
                      style={{ textAlign: "center" }}
                    >
                      <FaEdit
                        onClick={() => {
                          if (item.assets_status === true) {
                            setSnackbar({
                              open: true,
                              message: "Already Merged",
                              severity: "error",
                            });
                            return;
                          }
                          editActions(item.Template_Name, item?.QR1, item?.QR5_3COL, item?.QR4_POLL, item);
                        }}
                        style={{ fontSize: 18, cursor: "pointer", color:item.assets_status === true  && 'grey' }}
                      />
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell style={{ textAlign: "center" }} colSpan={10}>
                  Record Not Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Popup
        title="Please enter the details"
        open={addQR}
        overflowY="auto"
        // height="380px"
        content={
          <>
            <EditQRDetails
              close={() => setAddQR(false)}
              setIsLoading={setIsLoading}
              setSnackbar={setSnackbar}
              EditQRFields={EditQRFields}
              getData={getData}
              isLoading={isLoading}
              uploadAssetsData={data.uploadAssetsData}
              // getData={getData}
            />
          </>
        }
      />
      <Message snackbar={snackbar} handleCloseSnackbar={handleCloseSnackbar} />
      <Loading isLoading={isLoading} height={80} width={80} color="#15223F" />
    </MainCard>
  );
};

export default ViewAssets;
