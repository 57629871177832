import React from "react";
import MainCard from "ui-component/cards/MainCard";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect } from "react";
import { useState } from "react";
import Axios from "api/Axios";
import { API } from "api/API";
import Message from "components/Snackbar/Snackbar";
import Loading from "components/Loading";
import { useLocation } from "react-router";
import ReusableMenu from "components/Menu";
import { saveAs } from "file-saver";
import { PiFileCsvBold } from "react-icons/pi";

const Hanger_Comp_Data = () => {
  const location = useLocation();
  const data = location.state;
  // console.log(data)
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    // severity: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showData, setShowData] = useState([]);

  const serialNumber = (page, index) => {
    return (page - 1) * rowsPerPage + index + 1;
  };

  const columns = [
    { id: "sno", label: "#" },
    { id: "poster_code", label: "Poster Code" },
    { id: "actions", label: "Actions" },
    // ...requiredFields.map((item) => ({ id: item, label: item })),
  ];

  const Get_All_listings = async () => {
    const datas = {
      pre_print_mergename_id: data.printid,
      post_order: data.post_order,
      merged_name: data.uploadAssetsData.merged_name,
      type: data.type, file_name: data.uploadAssetsData.file_name
    };
    try {
      setIsLoading(true);
      const response = await Axios.post(API.Get_hanger_Comp_CSV_data, datas);
      if (response.status === 200) {
        setShowData(response.data);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err, "Error while getting Categories");
      setIsLoading(false);
      setShowData([]);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({
      open: false,
      message: "",
      severity: snackbar.severity,
    });
  };

  const handleClick = async (option, POSTER_CODE_1) => {
    if (option === "Merge Printables") {
      // console.log("merge printables");
      const post_code = POSTER_CODE_1;
      await MergePrintables(post_code);
    }
  };

  const MergePrintables = async (poster_code) => {
    const values = {
      merged_name: data.uploadAssetsData.merged_name,
      post_order: data.post_order,
      POSTER_CODE_1: poster_code,
      type: data.type,
    };
    try {
      setIsLoading(true);
      const result = await Axios.post(API.Hanger_Comp_Merge, values);
      if (result.status === 200) {
        setIsLoading(false);
        // console.log("result", result.data);
        Get_All_listings();

        setSnackbar({
          open: true,
          message: result.data.message,
          severity: "success",
        });
      }
    } catch (error) {
      console.log(error, "Error while Merge Printable API Response");
      setIsLoading(false);
      setSnackbar({
        open: true,
        message: error.response.data.message || error.response.data.error,
        severity: "error",
      });
    }
  };

  const handleCSV = async () => {
    const values = {
      merged_name: data.uploadAssetsData.merged_name,
      post_order: data.post_order,
      type: data.type,
      file_name: data.uploadAssetsData.file_name,
    };
    const APIURL =
    `${API.Export_Merge_Hanger_comp_Data}?` +
      `${values.merged_name ? `merged_name=${values.merged_name}&` : ""}` +
      `${values.post_order ? `post_order=${values.post_order}&` : ""}` +
      `${values.type ? `type=${values.type}&` : ""}` +
      `${values.file_name ? `file_name=${values.file_name}&` : ""}`;
          try {
      setIsLoading(true);
      const response = await Axios.get(APIURL);
      if (response.status === 200) {
        setIsLoading(false);
        const fileName =
          data.type === "comp"
            ? "QRCode_PDF_Comps.csv"
            : "QRCode_PDF_Hanger.csv";
        saveAs(new Blob([response.data]), fileName);
      }
    } catch (err) {
      console.log(err, "Error while getting Categories");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    Get_All_listings();
  }, []);

  return (
    <MainCard
      title="View Data"
      buttontitle1={
        data.type === "comp" ? "Export Merged Comps" : "Export Merged Hanger"
      }
      disabled={showData.finalStatus === false ?? true}
      display={showData.finalStatus === false ?? 'none'}
      approveColor="#15223f"
      startIcon={<PiFileCsvBold />}
      approve={handleCSV}
    >
      <TableContainer
        className="chips"
        component={Paper}
        style={{ border: "1px solid black" }}
      >
        <Table>
          <TableHead>
            <TableRow style={{ background: "#364152", border: "none" }}>
              {columns.map((column) => (
                <TableCell
                  style={{ textAlign: "center", color: "#fff", border: "none" }}
                  className="headings"
                  // style={{ color: "#fff" }}
                  key={column.id}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {showData && showData?.data?.length > 0 ? (
              showData.data.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell
                      style={{ textAlign: "center" }}
                      className="common"
                      padding="checkbox"
                    >
                      {serialNumber(page, index)}
                    </TableCell>

                    <TableCell className="common" sx={{ width: 500 }}>
                      {item?.POSTER_CODE_1}
                    </TableCell>
                    <TableCell className="common" sx={{ textAlign: "center" }}>
                      <ReusableMenu
                        APICall="no"
                        options={["Merge Printables"]}
                        handleOption={(option) => {
                          handleClick(option, item?.POSTER_CODE_1);
                        }}
                        YourSelection={(item?.comps_status === true || item?.hanger_status === true) ? ["Merge Printables"] : []}
                      />
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell style={{ textAlign: "center" }} colSpan={10}>
                  Record Not Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Message snackbar={snackbar} handleCloseSnackbar={handleCloseSnackbar} />
      <Loading isLoading={isLoading} height={80} width={80} color="#15223F" />
    </MainCard>
  );
};

export default Hanger_Comp_Data;
