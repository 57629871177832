export const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const years = ['2024', '2025'];

export const Edition_Names_FrenchBlue = [
  'Allergy & Immunology',
  'Allergy &amp; Immunology',
  'Behavioral Health',
  'Burn Care',
  'Cardiology',
  'Dermatology',
  'Ear, Nose & Throat',
  'Ear Nose Throat',
  'Emergency',
  'Endocrinology',
  'Gastroenterology',
  'Hematology',
  'Infectious Disease',
  'Infusion Care',
  'Nephrology',
  'Neurology',
  'OB/GYN',
  'Ophthalmology',
  'Oncology',
  'Pain Management',
  'Pediatrics',
  'Pharmacy',
  'PW Core',
  'Primary Care',
  'Pulmonology',
  'Rheumatology',
  'Surgery',
  'Urology',
];

export const Edition_Names_NavyBlue = [
  'Breast Cancer',
  'COPD and Asthma',
  'Endometrial Cancer',
  'HIV & Retrovirus',
  'HIV &amp; Retroviruses',
  'HIV PrEP',
  'Hypertrophic Cardiomyopathy',
  'Inherited Retinal Disease',
  'Liver Disease',
  'Lung Cancer',
  'Multiple Sclerosis',
  'Myelofibrosis',
  'MASH',
  'Obesity and Weight Loss',
  'Plaque Psoriasis',
  'Psoriatic Arthritis',
  'Pulmonary Arterial Hypertension',
  'Rare Blood Disorders',
  'Respiratory Syncytial Virus',
  'Skin Cancer',
  'Type 2 Diabetes',
];
