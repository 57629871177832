// import { configureStore } from "@reduxjs/toolkit";
// import customizationReducer from "./customizationReducer";
// import Data from "./Data";

// export const store = configureStore({
//   reducer: {
//     Data: Data,
//     customization: customizationReducer,
//   },
// });

// import { configureStore } from "@reduxjs/toolkit";
// import customizationReducer from "./customizationReducer";
// import Data from "./Data";
// import storage from "redux-persist/lib/storage";
// import { persistStore, persistReducer } from "redux-persist";


// const persistConfig = {
//   key: 'DataPersisted',
//   storage
// }

// // Create the Redux store
// const store = configureStore({
//   reducer: {
//     Data: persistReducer(persistConfig, Data),
//     customization: customizationReducer,
//   },
// });

// // Create the persisted store
// const persistor = persistStore(store);


// // Export the store and persistor
// export { store, persistor };


import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import customizationReducer from "./customizationReducer";
import Data from "./Data";
import storage from "redux-persist/lib/storage";
import { persistStore, persistReducer } from "redux-persist";

const persistConfig = {
  key: 'DataPersisted',
  storage,
}

// Create a persisted reducer for Data
const persistedDataReducer = persistReducer(persistConfig, Data);

// Configure the store with the persisted reducer and the customization reducer
const store = configureStore({
  reducer: {
    Data: persistedDataReducer,
    customization: customizationReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [ // Ignore these actions to avoid serializable check error
        'persist/PERSIST',
        'persist/REHYDRATE',
      ],
    },
  }),
});

// Create the persisted store
const persistor = persistStore(store);

// Export the store and persistor
export { store, persistor };

