export const requiredFields = ['GUID','Email', 'FirstName', 'LastName' ];


 export const monthss = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  export const fields = [
    'POSTER_CODE',
    'Template_Name',
    'QR1',
    'QR2_ENL',
    'QR3_POD',
    'QR4_POLL',
    'QR5_3COL',
    'QR6_Perks',
    'QR7',
    'QR8',
    'QR9',
    'QR10',
    'PDF_Manual',
    'PDF_AI',
    'PDF_AD_1',
    'PDF_AD_2',
    'PDF_URL'
  ];

  export const Postup_Email_List =[
    "Poster Code", "GUID", "LID", "Print Order","Batch Number", "Package ID", 'Email', 'First Name', 'Last Name', "POSTER_TITLE", 'File Name'
  ]

  function createSuperscriptText(base, superscript) {
    return `${base}<span style="vertical-align: super; font-size: smaller;">${superscript}</span>`;
}


  export const RSS_feed_fields = [
    "First Name", "Last Name", "Email", 'MAILING ID', 'Mailing Reference Number', "Poster Name", createSuperscriptText("1", "st") + " Article Image", createSuperscriptText("1", "st") + " Article Link",createSuperscriptText("1", "st") +  " Article Title", createSuperscriptText("2", "nd") + " Article Link", createSuperscriptText("2", "nd") + " Article Title", createSuperscriptText("3", "rd") + " Article Link",createSuperscriptText("3", "rd") + " Article Title", createSuperscriptText("4", "th") + " Article Link",createSuperscriptText("4", "th") + " Article Title", createSuperscriptText("5", "th") +" Article Link", createSuperscriptText("5", "th") +" Article Title", createSuperscriptText("6", "th") + " Article Link", createSuperscriptText("6", "th") +  " Article Title"
  ]