import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { FaFileCsv, FaFilePdf } from "react-icons/fa";
import { useTheme } from "@mui/material/styles";

const fileFields = [{ name: "assets", label: "Upload Assets" }];

const validationSchema = Yup.object().shape({
  assets: Yup.mixed().required("Assest are required"),
});

const Upload_Assets = ({
  isLoading,
  setIsLoading,
  setSnackbar,
  close,
  uploadAssetsData,
  Axios, API, ChildListing,
  ...others
}) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));

  const AllValues = {
    assets: "",
  };

  const replaceSlashWithDash = (inputString) => {
    return inputString.replace(/\//g, "-");
  };

  const FileChangeHandler = (fieldName, setFieldValue) => (event) => {
    let files = event.target.files;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file && file.name.toLowerCase().endsWith(".pdf")) {
        let poster_code = replaceSlashWithDash(uploadAssetsData.post_code);
        // Check if file name contains the poster_code
        if (file.name.includes(poster_code)) {
          //   console.log("File name matched:", file.name);
          setFieldValue(fieldName, files);
        } else {
          setSnackbar({
            open: true,
            severity: "error",
            message: `File Name Not Matched, Please upload the correct version.`,
          });
          event.target.value = "";
          return; // Exit loop if any file name doesn't match
        }
      } else {
        setSnackbar({
          open: true,
          severity: "error",
          message: `Please select PDF Only`,
        });
        event.target.value = "";
        setFieldValue(fieldName, []);
        return; // Exit loop if any file is not a PDF
      }
    }
  };

  const handleSubmit = async (values) => {
    const formData = new FormData();
    const Assets = Array.from(values.assets);
    Assets.forEach((file) => {
      formData.append("assetFiles", file);
    });
    const data ={
      month: uploadAssetsData.month,
      year: uploadAssetsData.year,
      post_order: uploadAssetsData.post_order,
      merged_name: uploadAssetsData.merged_name,
      post_code: uploadAssetsData.post_code,
      batch_number:uploadAssetsData.batch_number,
      file_name:uploadAssetsData.file_name,
    }
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        formData.append(key, data[key]);
      }
    }
    try {
      setIsLoading(true);
      const result = await Axios.Filepost(API.Upload_PrePrint_Assets, formData);
      if (result.status === 200) {
        // console.log(result.data, "hello I am in");
        ChildListing();
        close();
        setSnackbar({open:true, 
          message:result.data.msg, 
          severity:'success'
        })
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error, "Error while getting API Response");
      setIsLoading(false);
      setSnackbar({
        open:true, 
        severity:'error',
        message: error.response.data.error || error.message
      })
    }
  };

  return (
    <>
      <Formik
        initialValues={AllValues}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          //   console.log(values, "hello values");
          handleSubmit(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          values,
        }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            {...others}
            autoComplete="off"
          >
            <Grid
              container
              spacing={matchDownSM ? 0 : 2}
              sx={{ display: "flex", alignItems: "center" }}
            >
              {/* Files */}
              {fileFields.map((field) => (
                <Grid item xs={12} key={field.name}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched[field.name] && errors[field.name])}
                    sx={{ ...theme.typography.customInput }}
                  >
                    <InputLabel htmlFor={`outlined-adornment-${field.name}`}>
                      {field.label}
                    </InputLabel>
                    <Input
                      id={`outlined-adornment-${field.name}`}
                      type="file"
                      name={field.name}
                      inputProps={{
                        accept: ".pdf",
                        multiple: true, // Allow multiple file selection
                      }}
                      onBlur={handleBlur}
                      onChange={FileChangeHandler(field.name, setFieldValue)}
                      endAdornment={
                        <InputAdornment
                          sx={{ fontSize: 22, marginTop: 2 }}
                          position="end"
                        >
                          <FaFilePdf />
                        </InputAdornment>
                      }
                    />
                    {touched[field.name] && errors[field.name] && (
                      <FormHelperText
                        error
                        id={`standard-weight-helper-text-${field.name}`}
                      >
                        {errors[field.name]}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              ))}
            </Grid>

            <div style={{ textAlign: "center", marginTop: 10 }}>
              <Button
                size="large"
                variant="contained"
                style={{
                  background: "#C62828",
                  marginRight: "10px",
                  color: isLoading === true && "#fff",
                }}
                onClick={close}
                disabled={isLoading === true ?? true}
              >
                Close
              </Button>
              <Button
                size="large"
                type="submit"
                variant="contained"
                style={{
                  background: "#1d213e",
                  marginRight: "15px",
                  color: isLoading === true && "#fff",
                }}
                disabled={isLoading === true ?? true}
              >
                Submit
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default Upload_Assets;
