import React, { useState, useEffect } from 'react';
import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  Pagination,
  Toolbar,
  alpha,
  InputLabel,
  OutlinedInput,
  Collapse,
  IconButton,
  Box,
  Tooltip,
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useTheme } from '@mui/material/styles';

// icons
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FaPlus } from 'react-icons/fa';
import { IoIosSave } from 'react-icons/io';

// Components
import MainCard from 'ui-component/cards/MainCard';
import Message from 'components/Snackbar/Snackbar';
import Loading from 'components/Loading';
import Popup from 'components/Popup';
import { monthss } from '../PrePrint/Fields';
import { MENU_OPEN } from 'store/actions';
import { API } from 'api/API';
import Axios from 'api/Axios';
import '../PrePrint/style.css';
import ReusableMenu from 'components/Menu';
import { categories, MetaFields } from './Fields';
import CreateVersions from './Versions/CreateVersions';
import GroupedAutocomplete from 'components/AutoComplete/GrouppedAutocomplete';

const validationSchema = Yup.object().shape({
  wallboards: Yup.array().of(
    Yup.object().shape({
      year: Yup.mixed().required('Year  is required'),
      month: Yup.mixed().required('Month  is required'),
    })
  ),
});

// Define your text fields here
const textFields = [
  { name: 'column1', label: 'Column 1' },
  { name: 'column2', label: 'Column 2' },
  { name: 'column3', label: 'Column 3' },
  { name: 'column4', label: 'Column 4/Article 1' },
  { name: 'column5', label: 'Column 4/Article 2' },
  { name: 'column6', label: 'Column 4/Article 3' },
  { name: 'column7', label: 'Column 4/Article 4' },
];

const Metadata = ({ ...others }) => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);
  const [showData, setShowData] = useState([]);
  const [popup, setPopup] = useState({ createVersions: false });
  const [open, setOpen] = useState(false);
  const [next, setNext] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState([]);
  const [values, setValues] = useState([]);

  const [rowExpansion, setRowExpansion] = useState(Array(showData.length).fill(false));
  const [MenuChecks, setMenuChecks] = useState([]);
  const [RWO_Type, setRwoType] = useState({
    hanger: false,
    comp: false,
    RWO: false,
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    // severity: "",
  });
  const [uploadCheck, setUploadCheck] = useState({
    pre_print_mergename_id: '',
    post_order: '',
    merged_name: '',
    batch_number: '',
  });
  const [subListingData, setSubListingData] = useState([]);
  const [uploadAssetsData, setUploadAssetsData] = useState({
    year: '',
    month: '',
    post_order: '',
    post_code: '',
    merged_name: '',
    batch_number: '',
    pre_print_id: '',
    file_name: '',
  });

  const [childRowExpansion, setChildRowExpansion] = useState(
    Array(subListingData.length).fill(false)
  );

  const [childListingData, setChildListingdata] = useState([]);

  const handleCloseSnackbar = () => {
    setSnackbar({
      open: false,
      message: '',
      severity: snackbar.severity,
    });
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const years = ["2021", "2022", "2023", "2024"];
  const years = ['2024', '2025'];
  const GetItem = localStorage.getItem('Profile_Details');
  const Details = JSON.parse(GetItem);

  const handleRowToggle = (index, prePrintID) => {
    setRowExpansion((prevRowExpansion) => {
      setChildRowExpansion(Array(subListingData.length).fill(false));
      const newRowExpansion = [...prevRowExpansion];
      newRowExpansion.fill(false); // Close all rows
      newRowExpansion[index] = !prevRowExpansion[index]; // Toggle the clicked row
      return newRowExpansion;
    });
  };

  const handleChildRowToggle = (index, prePrintID, postOrder) => {
    setChildRowExpansion((prevRowExpansion) => {
      const newRowExpansion = [...prevRowExpansion];
      newRowExpansion.fill(false); // Close all rows
      newRowExpansion[index] = !prevRowExpansion[index]; // Toggle the clicked row
      return newRowExpansion;
    });
  };

  const SubListing = async (prePrintID) => {
    try {
      setIsLoading(true);
      const result = await Axios.post(API.Sublisting_PrePrint, {
        pre_print_mergename_id: prePrintID,
      });
      if (result.status === 200) {
        setTimeout(() => {
          setSubListingData(result.data.csvData);
          setIsLoading(false);
        }, 1000);
      }
    } catch (error) {
      console.log(error, 'Error while getting API Response');
      setIsLoading(false);
    }
  };

  const handleIconClick = async (item) => {
    // console.log(item, "hello Item");
    const result = await ChildListing(
      item.pre_print_mergename_id,
      item.post_order,
      item.merged_name,
      item.batch_number,
      item.file_name
    );

    // console.log(result, "hello result");

    setUploadCheck({
      pre_print_mergename_id: item.pre_print_mergename_id,
      post_order: item.post_order,
      merged_name: item.merged_name,
      batch_number: item.batch_number,
    });

    setUploadAssetsData((prevState) => ({
      ...prevState,
      post_order: item.post_order,
      batch_number: item.batch_number,
      file_name: item.file_name,
      post_code: result[0]?.POSTER_CODE_1,
    }));
  };

  const handleArrowDownIcon = async (prePrintId) => {
    await SubListing(prePrintId);
    setUploadCheck((prevState) => ({
      ...prevState,
      pre_print_mergename_id: prePrintId,
    }));
  };

  const handleIconButtonClick = (index, item) => {
    handleRowToggle(index, item.pre_print_id);

    setUploadAssetsData((prevState) => ({
      ...prevState,
      month: item.month,
      year: item.year,
      merged_name: item.merged_name,
      // file_name: item.file_name,
      // batch_number: item.batch_number,
    }));
  };

  const columns = [
    { id: 'sno', label: '#' },
    ...MetaFields.map((item) => ({ id: item, label: item })),
    { id: 'actions', label: 'Actions' },
  ];

  const ChildListing = async (
    pre_print_mergename_id,
    post_order,
    merged_name,
    batch_number,
    file_name
  ) => {
    const data = {
      pre_print_mergename_id:
        pre_print_mergename_id === undefined
          ? uploadCheck.pre_print_mergename_id
          : pre_print_mergename_id,
      post_order: post_order === undefined ? uploadCheck.post_order : post_order,
      merged_name: merged_name === undefined ? uploadCheck.merged_name : merged_name,
      batch_number: batch_number === undefined ? uploadCheck.batch_number : batch_number,
      file_name: batch_number === undefined ? uploadAssetsData.file_name : file_name,
    };
    try {
      setIsLoading(true);
      const result = await Axios.post(API.ChildListing_Preprint, data);
      if (result.status === 200) {
        setTimeout(() => {
          setIsLoading(false);
          setChildListingdata(result.data.data);
        }, 500);
        return result.data.data;
      }
    } catch (error) {
      console.log(error, 'Error while getting API Response');
      setIsLoading(false);
    }
  };

  const serialNumber = (page, index) => {
    return (page - 1) * rowsPerPage + index + 1;
  };

  const handleChangePage = async (event, newPage) => {
    try {
      setPage(newPage);
      // console.log(FilterData, "filterDat")
      await Get_All_listings(newPage);
    } catch (error) {
      console.log('Error in Pagination', error);
    }
  };

  const handleRWO = async (values) => {
    try {
      if (!values.year || values.year.length === 0) {
        setSnackbar({
          open: true,
          severity: 'warning',
          message: 'Please select the Year',
        });
      } else if (!values.month || values.month.length === 0) {
        setSnackbar({
          open: true,
          severity: 'warning',
          message: 'Please select the Month',
        });
      } else {
        const data = {
          year: values.year,
          month: values.month,
          merged_name: `${values.month} ${values.year}`,
        };
        setRwoType({ RWO: true });
        setOpen(true);
      }
    } catch (error) {
      console.log(error, 'Hello I am an Error');
      setIsLoading(false);
      setSnackbar({
        open: true,
        severity: 'error',
        message: error.response.data.error,
      });
    }
  };

  const Get_All_listings = async (page) => {
    try {
      setIsLoading(true);
      const response = await Axios.get(`${API.PrePrintListings}?page=${page}`);
      if (response.status === 200) {
        // console.log(response.data, "response");
        setShowData(response.data.data);
        setNext(response?.data);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err, 'Error while getting Listings');
      setShowData([]);
      setIsLoading(false);
    }
  };

  const createVersions = (item) => {
    setPopup({ createVersions: true });
  };

  // const handleChange = (index, event, value) => {
  //   const updatedValues = [...values];

  //   if (!updatedValues[index]) {
  //     updatedValues[index] = {};
  //   }

  //   updatedValues[index].Template = value?.title || "";

  //   setValues(updatedValues);
  // };

  const handleChange = (index, e, autocompleteValue) => {
    const { name, value } = e?.target || {};

    const updatedValues = [...values];

    // Ensure the object at the current index is initialized
    if (!updatedValues[index]) {
      updatedValues[index] = {
        Template: '',
        column1: '',
        column2: '',
        column3: '',
        column4: '',
        column5: '',
        column6: '',
        column7: '',
      };
    }

    // Handle changes from the Autocomplete component
    if (autocompleteValue) {
      updatedValues[index].Template = autocompleteValue?.title || '';
    } else {
      // Handle changes from the TextField component
      updatedValues[index] = {
        ...updatedValues[index],
        [name]: value,
      };
    }
    setValues(updatedValues);
  };

  // const handleChange = (index, e) => {
  //   const { name, value } = e.target;
  //   const updatedValues = [...values];

  //   // If the row's state doesn't exist, initialize it
  //   if (!updatedValues[index]) {
  //     updatedValues[index] = {
  //       Template: "",
  //       column1: "",
  //       column2: "",
  //       column3: "",
  //       column4: "",
  //       column5: "",
  //       column6: "",
  //       column7: "",
  //     };
  //   }

  //   updatedValues[index] = {
  //     ...updatedValues[index],
  //     [name]: value,
  //   };
  //   setValues(updatedValues);
  // };

  const handleBlur = (index, e) => {
    const { name } = e.target;
    const updatedTouched = [...touched];

    if (!updatedTouched[index]) {
      updatedTouched[index] = {};
    }

    updatedTouched[index][name] = true;
    setTouched(updatedTouched);
  };

  // Helper function to find the category for a given template
  const findCategory = (template) => {
    for (let category in categories) {
      if (categories[category].includes(template)) {
        return category;
      }
    }
    return null;
  };

  // Initialize values state with API data or empty strings
  useEffect(() => {
    const initialValues = childListingData.map((item) => ({
      Template: item.Template_Name || '',
      column1: item.QR1 || '',
      column2: item.Article_2 || '',
      column3: item.QR5_3COL || '',
      column4: item.Article_1 || '',
      column5: item.Article_3 || '',
      column6: item.Article_6_Column_4_A3 || '',
      column7: item.Article_7_Column_4_A4 || '',
    }));
    setValues(initialValues);
  }, [childListingData]);

  // const validate = (fieldValues = values) => {
  //   let temp = { ...errors };

  //   // Iterate through the fieldValues to dynamically generate error messages
  //   for (let key in fieldValues) {
  //     if (fieldValues.hasOwnProperty(key)) {
  //       // Check for required fields
  //       temp[key] = fieldValues[key]
  //         ? ''
  //         : `${key.charAt(0).toUpperCase() + key.slice(1)} is required.`;

  //       // Additional validation for specific fields (like email)
  //       if (key === 'email') {
  //         temp.email = /^\S+@\S+\.\S+$/.test(fieldValues.email) ? '' : 'Email is not valid.';
  //       }
  //     }
  //   }

  //   setErrors({ ...temp });
  // };
  
  const validate = (index) => {
    let tempErrors = { ...errors };
  
    // Initialize error object for the current index if not present
    if (!tempErrors[index]) {
      tempErrors[index] = {};
    }
  
    // Validate only the Template field
    if (!values[index]?.Template) {
      tempErrors[index].Template = 'Template is required.';
    } else {
      // Clear error if Template is valid
      tempErrors[index].Template = '';
    }
  
    setErrors(tempErrors);
  
    // Return false if there are any errors for this row
    const hasErrors = Object.values(tempErrors[index]).some(error => error !== '');
    return !hasErrors; // Return true if no errors, false if there are any errors
  };

  const getData = async (PostCode) => {
    const data = {
      file_name: uploadAssetsData?.file_name,
      merged_name: uploadAssetsData?.merged_name,
      post_order: uploadAssetsData?.post_order,
      post_code: PostCode? PostCode : uploadAssetsData.post_code,
    };
    try {
      setIsLoading(true);
      const response = await Axios.post(API.Default_QR_Values, data);
      if (response.status === 200) {
        // console.log(response.data, "response");
        setIsLoading(false);
        return response.data;
      }
    } catch (err) {
      console.log(err, 'Error while QR Data');
      setIsLoading(false);
      setSnackbar({open:true, 
        message:err.response.data.message, 
        severity:'error'
      })
    }
  };

  const handleSave = async (index, item) => {
    const isValid = validate(index);

    // If the form is not valid, prevent the submission
    if (!isValid) {
      setTouched((prevTouched) => {
        const updatedTouched = [...prevTouched];
        updatedTouched[index] = { Template: true, ...updatedTouched[index] };
        textFields.forEach((field) => {
          updatedTouched[index][field.name] = true;
        });
        return updatedTouched;
      });
      return; // Prevent submission if validation fails
    }
    const data = await getData(item.POSTER_CODE_1);
    const updatedData = JSON.parse(
      JSON.stringify(data).replace(/QR6-Perks/g, "QR6_Perks")
    );    
    const payload = {
      ...updatedData,
      Template_Name: values[index].Template,
      QR2_ENL: 'https://preferences.physiciansweekly.com/newsletter-signup',
      QR3_POD: 'https://www.physiciansweekly.com/podcast/',
      QR4_POLL: 'https://www.physiciansweekly.com/poll-of-the-month/',
      QR1: values[index].column1,
      Article_2: values[index].column2,
      //QR5_3COL: values[index].column3,
      Article_1: values[index].column4,
      Article_3: values[index].column5,
      ['QR5_3COL/Article 3/Column 3']: '',
      QR5_3COL: values[index].column3,
      Article_6_Column_4_A3: values[index].column6,
      Article_7_Column_4_A4: values[index].column7,
      batch_number: uploadAssetsData.batch_number,
      file_name: uploadAssetsData.file_name,
      merged_name: uploadAssetsData.merged_name,
      post_order: uploadAssetsData.post_order,
      // POSTER_CODE: uploadAssetsData.post_code,
      POSTER_CODE: item.POSTER_CODE_1,
      manual_printable_id: item?.manual_printable_id
    };
    try {
      setIsLoading(true);
      const response = await Axios.post(API.AddQR, payload);
      if (response.status === 200) {
        // console.log(response.data, "response");
        setIsLoading(false);
        setSnackbar({
          open: true,
          severity: 'success',
          message: response.data.message,
        });
      }
    } catch (err) {
      console.log(err, 'Error while QR Data');
      setIsLoading(false);   
    }
  };

  const options = Object.entries(categories).reduce((acc, [category, templates]) => {
    if (Array.isArray(templates)) {
      templates.forEach((template) => {
        acc.push({ category, template });
      });
    }
    return acc;
  }, []);

  useEffect(() => {
    Get_All_listings(page);
    dispatch({ type: MENU_OPEN, id: 'metadata' });
    setChildRowExpansion(Array(subListingData.length).fill(false));
    setRowExpansion(Array(showData.length).fill(false));
  }, []);

  useEffect(() => {
    setChildListingdata((previousData) => previousData);
  }, [childListingData]);

  // console.log(getValues, "RwoType");
  return (
    <MainCard title="Meta Data">
      {/* <ComingSoon /> */}
      {/* <Formik
        style={{ marginBottom: 6 }}
        initialValues={{
          month: "",
          year: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          handleRWO(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          setFieldValue,
          setYear,
          resetForm,
          values,
        }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            {...others}
            autoComplete="off"
          >
            <div className="xyz">
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 1 }}
                sx={{ alignItems: "center", marginBottom: 5 }}
              >
                <Grid item columnSpacing={{ xs: 1, sm: 2, md: 1 }}>
                  <Typography variant="subtitle1">RWO:</Typography>
                </Grid>

                <Grid item xs={2}>
                  <FormControl fullWidth>
                    <Autocomplete
                      autoHighlight
                      options={years || []}
                      getOptionLabel={(option) => option || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Year"
                          variant="outlined"
                          post_order
                        />
                      )}
                      name="year"
                      value={
                        values.year
                          ? years.find((item) => item === values.year)
                          : null
                      }
                      onChange={(_, newValue) => {
                        if (newValue?.length === 0 || newValue === null) {
                          setFieldValue(`month`, []);
                        }
                        setFieldValue(`year`, newValue ? newValue : []);
                      }}
                      noOptionsText="No Results Found"
                    />
                    {touched.year && errors.year && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-year"
                      >
                        {errors.year}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={3}>
                  <FormControl fullWidth>
                    <Autocomplete
                      autoHighlight
                      options={monthss || []}
                      getOptionLabel={(option) => option || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Month"
                          variant="outlined"
                        />
                      )}
                      name="month"
                      value={
                        values.month
                          ? monthss.find((item) => item === values.month)
                          : null
                      }
                      onChange={(_, newValue) => {
                        setFieldValue(`month`, newValue);
                      }}
                      // onFocus={() => Get_Wallboard_Months(values.year)}
                      noOptionsText="No Results Found"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={5} style={{ display: "flex", gap: "10px" }}>
                  <Button
                    disableElevation
                    disabled={isSubmitting}
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    style={{
                      background: "#15223F",
                      padding: "12px 20px",
                      borderRadius: "8px",
                      color: isSubmitting && "white",
                      pointerEvents: "none",
                    }}
                  >
                    Filter
                  </Button>
                  <Button
                    size="large"
                    variant="contained"
                    color="secondary"
                    style={{
                      background: "#C62828",
                      padding: "12px 20px",
                      borderRadius: "8px",
                      color: isSubmitting && "white",
                    }}
                    onClick={() => {
                      resetForm();
                      setFieldValue("year", "");
                    }}
                  >
                    Clear
                  </Button>
                </Grid>
              </Grid>
            </div>
          </form>
        )}
      </Formik> */}

      <TableContainer className="chips" component={Paper} style={{ border: '1px solid black', borderBottomLeftRadius:0, borderBottomRightRadius:0 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ textAlign: 'center' }} className="headings">
                #
              </TableCell>
              <TableCell className="headings">Meta Data</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {showData && showData.length > 0 ? (
              showData.map((item, index) => {
                return (
                  <>
                    <TableRow key={index}>
                      <TableCell style={{ textAlign: 'center' }} padding="checkbox">
                        {/* {serialNumber(page, index)} */}
                        <IconButton
                          aria-label="expand row"
                          size="small"
                          onClick={() => handleIconButtonClick(index, item)}
                        >
                          {
                            // listingOpen
                            rowExpansion[index] ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon
                                onClick={() => handleArrowDownIcon(item.pre_print_id)}
                              />
                            )
                          }
                        </IconButton>
                      </TableCell>
                      <TableCell className="common">
                        {item?.merged_name}
                        <br />
                        <Collapse in={rowExpansion[index]} timeout="auto" unmountOnExit>
                          <Box sx={{ margin: 1 }}>
                            <Typography variant="h6" gutterBottom component="div">
                              Details
                            </Typography>
                            <Table size="small" aria-label="purchases" className="dataTable">
                              <TableHead>
                                <TableRow key={index}>
                                  <TableCell>#</TableCell>
                                  <TableCell>Month/Year</TableCell>
                                  <TableCell>Print order</TableCell>
                                  <TableCell>RWO\Batch Number</TableCell>
                                  {/* <TableCell>Actions</TableCell> */}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {subListingData.length > 0 ? (
                                  subListingData.map((item, index) => (
                                    <>
                                      <TableRow className="child" key={index}>
                                        <TableCell>
                                          <IconButton
                                            aria-label="expand row"
                                            size="small"
                                            // onClick={() => setListingOpen(!listingOpen)}
                                            onClick={() => handleChildRowToggle(index)}
                                          >
                                            {
                                              // listingOpen
                                              childRowExpansion[index] ? (
                                                <KeyboardArrowUpIcon />
                                              ) : (
                                                <KeyboardArrowDownIcon
                                                  onClick={() => handleIconClick(item)}
                                                />
                                              )
                                            }
                                          </IconButton>
                                        </TableCell>
                                        <TableCell>{item?.merged_name}</TableCell>
                                        <TableCell component="th" scope="row">
                                          {item?.post_order}
                                        </TableCell>
                                        <TableCell>{item?.batch_number}</TableCell>
                                        {/* <TableCell>
                                          <ReusableMenu options={[]} />
                                        </TableCell> */}
                                      </TableRow>
                                      <TableRow>
                                        <TableCell style={{ borderBottom: 0 }} colSpan={5}>
                                          <div className="subchild">
                                            <Collapse
                                              in={childRowExpansion[index]}
                                              timeout="auto"
                                              unmountOnExit
                                            >
                                              <Box sx={{ margin: 1 }}>
                                                <Typography
                                                  variant="h6"
                                                  gutterBottom
                                                  component="div"
                                                >
                                                  Sub Details
                                                </Typography>
                                                <div size="small" aria-label="purchases">
                                                  <Table className="dataTable postTable">
                                                    <TableHead>
                                                      <TableRow
                                                        style={{
                                                          background: '#15223f',
                                                          border: 'none',
                                                        }}
                                                        key={index}
                                                      >
                                                        {columns.map((column) => (
                                                          <TableCell
                                                            style={{
                                                              textAlign: 'center',
                                                              color: '#fff',
                                                              border: 'none',
                                                              padding: '0px 20px',
                                                            }}
                                                            className="headings"
                                                            // style={{ color: "#fff" }}
                                                            key={column.id}
                                                          >
                                                            {column.label}
                                                          </TableCell>
                                                        ))}
                                                      </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                      {childListingData.length > 0 ? (
                                                        childListingData.map((item, index) => (
                                                          <TableRow key={index}>
                                                            <TableCell>
                                                              <FaPlus
                                                                onClick={
                                                                  item.version === true
                                                                    ? ()=>createVersions(item)
                                                                    : null
                                                                }
                                                                style={{
                                                                  cursor:
                                                                    item.version === false
                                                                      ? 'not-allowed'
                                                                      : 'pointer', // Change cursor
                                                                  color:
                                                                    item.version === false
                                                                      ? 'gray'
                                                                      : 'black', // Optionally change color
                                                                }}
                                                              />
                                                               <Popup
        title="How many versions do you want to create?"
        open={popup.createVersions}
        overflowY="auto"
        height="180px"
        content={
          <>
            <CreateVersions
              close={() => setPopup({ createVersions: false })}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
              setSnackbar={setSnackbar}
              uploadAssetsData={uploadAssetsData}
              ChildListing={ChildListing}
              item={item}
            />
          </>
        }
      />
                                                            </TableCell>
                                                            <TableCell>
                                                              {item.POSTER_CODE_1}
                                                            </TableCell>
                                                            <TableCell>
                                                              <GroupedAutocomplete
                                                                className="GroupedAutocomplete"
                                                                options={categories}
                                                                onChange={(event, value) =>
                                                                  handleChange(index, event, value)
                                                                }
                                                                label="Select Template"
                                                                value={
                                                                  values[index]?.Template
                                                                    ? {
                                                                        category: findCategory(
                                                                          values[index]?.Template
                                                                        ),
                                                                        title:
                                                                          values[index]?.Template,
                                                                      }
                                                                    : null
                                                                }
                                                                error={Boolean(
                                                                  touched[index]?.Template &&
                                                                    errors[index]?.Template
                                                                )}
                                                                helperText={
                                                                  touched[index]?.Template &&
                                                                  errors[index]?.Template
                                                                }
                                                              />
                                                            </TableCell>

                                                            {textFields.map((field) => (
                                                              <TableCell key={field.name}>
                                                                <Grid item xs={12} sm={6}>
                                                                  <FormControl
                                                                    fullWidth
                                                                    style={{ width: 150 }}
                                                                    error={Boolean(
                                                                      touched[index]?.[
                                                                        field.name
                                                                      ] &&
                                                                        errors[index]?.[field.name]
                                                                    )}
                                                                    sx={{
                                                                      ...theme.typography
                                                                        .customInput,
                                                                    }}
                                                                  >
                                                                    <InputLabel
                                                                      htmlFor={`outlined-adornment-${field.name}`}
                                                                    >
                                                                      {field.label}
                                                                    </InputLabel>
                                                                    <OutlinedInput
                                                                      id={`outlined-adornment-${field.name}`}
                                                                      type="text"
                                                                      value={
                                                                        values[index]?.[
                                                                          field.name
                                                                        ] || ''
                                                                      }
                                                                      name={field.name}
                                                                      onBlur={(e) =>
                                                                        handleBlur(index, e)
                                                                      }
                                                                      onChange={(e) =>
                                                                        handleChange(index, e)
                                                                      }
                                                                    />
                                                                    {touched[index]?.[field.name] &&
                                                                      errors[index]?.[
                                                                        field.name
                                                                      ] && (
                                                                        <FormHelperText
                                                                          error
                                                                          id={`standard-weight-helper-text-${field.name}`}
                                                                        >
                                                                          {
                                                                            errors[index][
                                                                              field.name
                                                                            ]
                                                                          }
                                                                        </FormHelperText>
                                                                      )}
                                                                  </FormControl>
                                                                </Grid>
                                                              </TableCell>
                                                            ))}

                                                            <TableCell>
                                                              <Button
                                                                size="medium"
                                                                type="submit"
                                                                variant="contained"
                                                                style={{
                                                                  background: '#1d213e',
                                                                  marginRight: '15px',
                                                                  color:
                                                                    isLoading === true && '#fff',
                                                                }}
                                                                disabled={
                                                                  isLoading === true ?? true
                                                                }
                                                                startIcon={<IoIosSave />}
                                                                onClick={() => handleSave(index, item)}
                                                              >
                                                                Save
                                                              </Button>
                                                            </TableCell>
                                                          </TableRow>
                                                        ))
                                                      ) : (
                                                        <TableRow>
                                                          <TableCell
                                                            style={{
                                                              textAlign: 'center',
                                                            }}
                                                            colSpan={10}
                                                          >
                                                            Record Not Found
                                                          </TableCell>
                                                        </TableRow>
                                                      )}
                                                    </TableBody>
                                                  </Table>
                                                </div>
                                              </Box>
                                            </Collapse>
                                          </div>
                                        </TableCell>
                                      </TableRow>
                                    </>
                                  ))
                                ) : (
                                  <TableRow>
                                    <TableCell style={{ textAlign: 'center' }} colSpan={5}>
                                      Record Not Found
                                    </TableCell>
                                  </TableRow>
                                )}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })
            ) : (
              <TableRow>
                <TableCell style={{ textAlign: 'center' }} colSpan={2}>
                  Record Not Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

     

      {showData?.length > 0 && next?.totalPages > 1 && (
        <Pagination
          count={next?.totalPages}
          defaultPage={1}
          page={page}
          onChange={handleChangePage}
          siblingCount={1}
          boundaryCount={1}
          color="primary"
          className="paging"
          sx={{
            button: { mt: 2 },
            width: '100%',
            display: 'flex',
            justifyContent: { xs: 'center', md: 'flex-end' },
          }}
        />
      )}

      <Message snackbar={snackbar} handleCloseSnackbar={handleCloseSnackbar} />
      <Loading isLoading={isLoading} height={80} width={80} color="#15223F" />
    </MainCard>
  );
};

export default Metadata;
