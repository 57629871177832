import React, { useState, useEffect } from "react";
import { fields, templateFields } from "../Fields";
import { Button, TextField } from "@mui/material";
import Axios from "api/Axios";
import { API } from "api/API";

const Qr = ({
  close,
  setIsLoading,
  setSnackbar,
  uploadAssetsData,
  ChildListing,
  SubListing,
  uploadCheck,
  isLoading,
}) => {
  const [errors, setErrors] = useState({});
  const [data, setData] = useState([]);
  const [fieldValues, setFieldValues] = useState({});

  const areSpecificPropertiesNull = (obj, properties) => {
    return properties.every((key) => obj[key] == null || obj[key] === "");
  };

  const handleFieldChange = (fieldName, value) => {
    setFieldValues((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const handleBlur = (field) => {
    const newErrors = { ...errors }; // Start with the current errors
    const value = fieldValues[field]?.trim(); // Get the trimmed value of the field
    const templateName = fieldValues["Template_Name"]; // Retrieve the Template_Name field value
    const propertiesToCheck = templateFields[templateName] || []; 
    // Validate the field if it's in the propertiesToCheck array
    if (propertiesToCheck.includes(field)) {
      if (!value) {
        newErrors[field] = `${field} is required.`;
      } else if (!validateURL(value)) {
        newErrors[field] = "Please enter a valid URL.";
      } else {
        // Check if the URL already exists in any other field
        const urlExistsInOtherField = propertiesToCheck.some(
          (prop) => prop !== field && fieldValues[prop]?.trim() === value
        );

        if (urlExistsInOtherField) {
          newErrors[field] = "This URL is already used in another field.";
        } else {
          delete newErrors[field]; // Remove the error if the field is valid
        }
      }
    }

    setErrors(newErrors); // Update the errors state
  };

  const validateURL = (url) => {
    // Regular expression to validate URL
    const urlRegex = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/i);
    // console.log(urlRegex.test(url), " hello url test");
    return urlRegex.test(url);
  };

  const handleSubmit = async () => {
    const newErrors = {};
    const templateName = fieldValues["Template_Name"];

    // Determine properties to check based on template names
    const propertiesToCheck = templateFields[templateName] || [];

    // Validate QR fields based on template names
    propertiesToCheck.forEach((field) => {
      if (!fieldValues[field] || fieldValues[field].trim() === "") {
        newErrors[field] = `${field} is required.`;
      } else if (!validateURL(fieldValues[field])) {
        newErrors[field] = "Please enter a valid URL.";
      }
    });

    // Check for duplicate URLs
    const urlMap = {};
    propertiesToCheck.forEach((field) => {
      const value = fieldValues[field]?.trim();
      if (value) {
        if (urlMap[value]) {
          newErrors[field] = "This URL is already used in another field.";
        } else {
          urlMap[value] = true;
        }
      }
    });

    setErrors(newErrors);

    // Check if there are any errors
    const NullCheck = areSpecificPropertiesNull(newErrors, propertiesToCheck);
    if (NullCheck && Object.keys(newErrors).length === 0) {
      const {
        ["Article 4/Column 4_A1"]: _,
        ["Article 2/Column 2"]: __,
        ["Article 5/Column 4_A2"]: ___,
        ["QR1/Article 1/Column 1"]: ____,
        ["Article 6/Column 4_A3"]: _____,
        ["Article 7/Column 4_A4"]: ______,
        ...restFieldValues
      } = fieldValues;
      const array = {
        ...restFieldValues,
        QR5_3COL: fieldValues["QR5_3COL/Article 3/Column 3"],
        Article_6_Column_4_A3: fieldValues["Article 6/Column 4_A3"],
        Article_7_Column_4_A4: fieldValues["Article 7/Column 4_A4"],
        QR1: fieldValues["QR1/Article 1/Column 1"],
        Article_1: fieldValues["Article 4/Column 4_A1"],
        Article_2: fieldValues["Article 2/Column 2"],
        Article_3: fieldValues["Article 5/Column 4_A2"],
        post_order: uploadAssetsData?.post_order,
        batch_number: uploadAssetsData?.batch_number,
        merged_name: uploadAssetsData.merged_name,
        file_name: uploadAssetsData.file_name,
      };

      try {
        setIsLoading(true);
        const response = await Axios.post(API.AddQR, array);
        if (response.status === 200) {
          setIsLoading(false);
          setSnackbar({
            open: true,
            severity: "success",
            message: response.data.message,
          });
          setErrors({});
          SubListing(uploadCheck.pre_print_mergename_id);
          ChildListing();
          close();
        }
      } catch (err) {
        console.log(err, "Error while getting Categories");
        setIsLoading(false);
        setSnackbar({
          open: true,
          severity: "error",
          message: err.response.data.error,
        });
      }
    }
  };

  const getData = async () => {
    const data = {
      file_name: uploadAssetsData?.file_name,
      merged_name: uploadAssetsData?.merged_name,
      post_order: uploadAssetsData?.post_order,
      post_code: uploadAssetsData.post_code,
    };
    try {
      setIsLoading(true);
      const response = await Axios.post(API.Default_QR_Values, data);
      if (response.status === 200) {
        console.log(response.data, "response");
        setIsLoading(false);
        const data = response.data;
        // setData(response.data)
        setFieldValues({
          ...fieldValues,
          PDF_AD_1: data.PDF_AD_1,
          PDF_AI: data.PDF_AI,
          PDF_Manual: data.PDF_Manual,
          POSTER_CODE_1: data.POSTER_CODE_1,
          QR2_ENL: data.QR2_ENL,
          QR3_POD: data.QR3_POD,
          QR4_POLL: data.QR4_POLL,
          QR6_Perks: data.QR6_Perks,
          Template_Name: data.Template_Name,
          POSTER_CODE: data.POSTER_CODE_1,
          "QR1/Article 1/Column 1": data.QR1,
          QR3_POD: data.QR3_POD,
          "QR5_3COL/Article 3/Column 3": data.QR5_3COL,
          QR6_Perks: data["QR6-Perks"],
          QR7: data.QR7,
          QR8: data.QR8,
          QR9: data.QR9,
          QR10: data.QR10,
          PDF_AD_2: data.PDF_AD_2,
          PDF_URL: data.PDF_URL,
        });
      }
    } catch (err) {
      console.log(err, "Error while QR Data");
      setIsLoading(false);
    }
  };

  const isFieldEnabled = (field, templateName) => {
    return templateFields[templateName]?.includes(field) || false;
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div style={{ marginTop: 7 }}>
      {fields.map((field, index) => (
        <TextField
          sx={{ marginBottom: 1.5 }}
          focused={isFieldEnabled(field, fieldValues.Template_Name)}
          disabled={!isFieldEnabled(field, fieldValues.Template_Name)}
          fullWidth
          key={index}
          label={field}
          variant="outlined"
          value={fieldValues[field] || ""}
          onChange={(e) => handleFieldChange(field, e.target.value)}
          onBlur={() => handleBlur(field)}
          error={!!errors[field]}
          helperText={errors[field]}
        />
      ))}

      <div style={{ textAlign: "center", marginTop: 10 }}>
        <Button
          size="large"
          variant="contained"
          style={{
            background: "#C62828",
            marginRight: "10px",
            color: isLoading === true && "#fff",
          }}
          onClick={close}
          disabled={isLoading === true ?? true}
        >
          Close
        </Button>
        <Button
          size="large"
          variant="contained"
          style={{
            background: "#1d213e",
            marginRight: "15px",
            color: isLoading === true && "#fff",
          }}
          onClick={handleSubmit}
          disabled={isLoading === true ?? true}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default Qr;
