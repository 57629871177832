import React from "react";
import "./style.css";
import { Button } from "@mui/material";
import { IoCloseCircle } from "react-icons/io5";
import { useEffect } from "react";
import Axios from "api/Axios";
import { useState } from "react";
import { Postup_APIS } from "api/API";
import images from "assets/images/Images";

const RSSFeedPopup = ({ setSnackbar, setIsLoading, RssData }) => {
  // console.log(RssData, 'RssData')
  const date = new Date();
  const year = date.getFullYear();

  return (
    <>
      <table width="100%" border={0} cellPadding={0} cellSpacing={0}>
        <tbody>
          <tr>
            <td
              className="pad0 data"
              align="center"
              bgcolor="#ecf2f9"
              style={{ padding: "10px 0" }}
            >
              <table
                width={600}
                className="wrapper"
                align="center"
                border={0}
                cellPadding={0}
                cellSpacing={0}
                role="presentation"
                style={{ tableLayout: "fixed" }}
              >
                {/* Header */}
                <tbody>
                  {/* <tr>
                    <td
                      className="data"
                      align="center"
                      style={{
                        fontSize: 12,
                        // fontFamily: "Lato", sans-serif,
                        padding: "8px 0",
                      }}
                    >
                      <a
                        className="data"
                        href="[-CLICK_TO_VIEW-]"
                        target="_blank"
                        style={{
                          color: "#00001b",
                          textDecoration: "underline",
                        }}
                      >
                        View in browser
                      </a>
                    </td>
                  </tr> */}
                  <tr>
                    <td
                      className="data"
                      bgcolor="#0b2240"
                      style={{ padding: "10px 0" }}
                    >
                      <table
                        width="100%"
                        border={0}
                        cellPadding={0}
                        cellSpacing={0}
                      >
                        <tbody>
                          <tr>
                            <td className="data" align="center">
                              <a
                                className="data"
                                href="https://www.physiciansweekly.com"
                                target="_blank"
                              >
                                <img
                                  src={images.Postup_Banner}
                                  alt=""
                                  width={140}
                                  style={{
                                    display: "block",
                                    height: "auto",
                                    border: 0,
                                    margin: "0 auto",
                                  }}
                                />
                              </a>
                            </td>
                          </tr>
                          <tr>
                            <td
                              className="data"
                              align="center"
                              style={{
                                color: "#ffffff",
                                fontSize: 38,
                                // fontFamily: '"EB Garamond",serif',
                                fontFamily: '"EB Garamond", serif',
                              }}
                            >
                              {RssData?.item?.POSTER_TITLE}
                              {/* [-POSTER_NAME-] */}
                            </td>
                          </tr>
                          <tr>
                            <td
                              className="data"
                              align="center"
                              style={{
                                color: "#ffffff",
                                fontSize: 14,
                                fontFamily: '"Lato", sans-serif',
                                paddingTop: 2,
                              }}
                            >
                              ARTICLES FROM THE LATEST EDITION
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td className="data" align="center">
                      <a
                        className="data"
                        href="https://www.physiciansweekly.com"
                        target="_blank"
                      >
                        <img
                          src={images.Postup_Advertisement}
                          className="full_img"
                          alt=""
                          width={600}
                          style={{
                            display: "block",
                            height: "auto",
                            border: 0,
                          }}
                        />
                      </a>
                    </td>
                  </tr>
                  {/* //Header */}
                  {/* Advertisement */}
                  <tr>
                    <td
                      className="data"
                      bgcolor="#ffffff"
                      align="center"
                      style={{
                        color: "#231f20",
                        fontSize: 12,
                        // fontFamily: '"Lato",Helvetica,Arial,sans-serif',
                        paddingTop: 36,
                      }}
                    >
                      Advertisement
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="data"
                      bgcolor="#ffffff"
                      style={{ padding: "10px 0 45px" }}
                    >
                      {/* JEENG 728x90 */}
                      <div className="pi_43597 jeeng">
                        {/* domain: physiciansweekly.com */}
                        {/*[if (gte mso 9)|(IE)]><table align="center" cellpadding="0" cellspacing="0" width="600"><tr><td><![endif]*/}
                        <table
                          width={600}
                          align="center"
                          border={0}
                          cellPadding={0}
                          cellSpacing={0}
                          style={{ width: "100%", maxWidth: 600 }}
                        >
                          <tbody>
                            <tr>
                              <td className="data" align="center">
                                <a
                                  className="data"
                                  href={`https://rs-stripe.physiciansweekly.com/stripe/redirect?cs_stripeid=43597&cs_offset=0&cs_email=${RssData?.item?.Email}&cs_sendid=${RssData?.data?.MAILINGID}&cs_esp=postup&dfp_postupid=${RssData?.data?.MAILING_REFERENCE_NUMBER}`}
                                  style={{
                                    borderStyle: "none",
                                    outline: "none",
                                    textDecoration: "none",
                                  }}
                                  target="_blank"
                                  rel="nofollow noopener"
                                >
                                  <img
                                    alt=""
                                    height="auto"
                                    src={`https://rs-stripe.physiciansweekly.com/stripe/image?cs_stripeid=43597&cs_offset=0&cs_email=${RssData?.item?.Email}&cs_sendid=${RssData?.data?.MAILINGID}&cs_esp=postup&dfp_postupid=${RssData?.data?.MAILING_REFERENCE_NUMBER}`}
                                    style={{
                                      display: "block",
                                      border: 0,
                                      height: "auto",
                                      lineHeight: "100%",
                                      outline: "none",
                                      textDecoration: "none",
                                      width: "100%",
                                      maxWidth: 600,
                                    }}
                                    width={600}
                                  />
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        {/*[if (gte mso 9)|(IE)]></td></tr></table><![endif]*/}
                      </div>
                      {/* JEENG 728x90 */}
                    </td>
                  </tr>
                  {/* //Advertisement */}
                  <tr>
                    <td
                      className="data"
                      bgcolor="#ffffff"
                      style={{ padding: "22px 10px 83px" }}
                    >
                      <table
                        width="100%"
                        border={0}
                        cellPadding={0}
                        cellSpacing={0}
                        role="presentation"
                      >
                        <tbody>
                          <tr>
                            <td className="data" style={{ paddingTop: 20 }}>
                              <table
                                width={450}
                                className="wrapper"
                                align="center"
                                border={0}
                                cellPadding={0}
                                cellSpacing={0}
                                role="presentation"
                              >
                                {/* Article Start */}
                                <tbody>
                                  {RssData?.data["1"]?.image_url ||
                                  RssData?.data["2"]?.image_url ||
                                  RssData?.data["3"]?.image_url ||
                                  RssData?.data["4"]?.image_url ||
                                  RssData?.data["5"]?.image_url ? (
                                    <tr>
                                      <td className="data">
                                        <a
                                          className="data"
                                          href={RssData?.data["1"]?.URL}
                                          target="_blank"
                                        >
                                          <img
                                            src={
                                              RssData?.data["1"]?.image_url ||
                                              RssData?.data["2"]?.image_url ||
                                              RssData?.data["3"]?.image_url ||
                                              RssData?.data["4"]?.image_url ||
                                              RssData?.data["5"]?.image_url
                                            }
                                            className="full_img"
                                            alt=""
                                            width={450}
                                            height={250}
                                            style={{
                                              display: "block",
                                              // height: "auto",
                                              border: 0,
                                              objectFit: "cover",
                                            }}
                                          />
                                        </a>
                                      </td>
                                    </tr>
                                  ) : null}
                                  {RssData?.data["1"]?.URL && (
                                    <>
                                      <tr>
                                        <td className="data">
                                          <table
                                            width="100%"
                                            border={0}
                                            cellPadding={0}
                                            cellSpacing={0}
                                            role="presentation"
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  className="data"
                                                  align="center"
                                                  style={{
                                                    fontSize: 23,
                                                    // fontFamily:
                                                    //   '"Lato",Helvetica,Arial,sans-serif',
                                                    fontWeight: 700,
                                                    padding: "15px 0 35px",
                                                  }}
                                                >
                                                  <a
                                                    href={
                                                      RssData?.data["1"]?.URL
                                                    }
                                                    target="_blank"
                                                    className="title data"
                                                    style={{
                                                      color: "#00001b",
                                                      textDecoration: "none",
                                                    }}
                                                  >
                                                    {RssData?.data["1"]?.title}
                                                  </a>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </>
                                  )}
                                  {/* Article End */}
                                  <tr>
                                    <td className="data">
                                      <table
                                        width="100%"
                                        border={0}
                                        cellPadding={0}
                                        cellSpacing={0}
                                        role="presentation"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              className="data"
                                              bgcolor="#0b2240"
                                              height={1}
                                              style={{
                                                fontSize: 1,
                                                lineHeight: 1,
                                              }}
                                            >
                                              &nbsp;
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="data"
                                              align="center"
                                              style={{
                                                color: "#0179d9",
                                                fontSize: 18,
                                                fontFamily:
                                                  '"Merriweather",Times,serif',
                                                textDecoration: "uppercase",
                                                fontWeight: 700,
                                                padding: "25px 10px",
                                              }}
                                            >
                                              ISSUE HIGHLIGHTS:
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="data"
                                              bgcolor="#0b2240"
                                              height={1}
                                              style={{
                                                fontSize: 1,
                                                lineHeight: 1,
                                              }}
                                            >
                                              &nbsp;
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                  {/* Article Start */}
                                  {RssData?.data["2"]?.URL && (
                                    <>
                                      <tr>
                                        <td
                                          className="data"
                                          style={{ padding: "35px 0 30px" }}
                                        >
                                          <table
                                            width="100%"
                                            border={0}
                                            cellPadding={0}
                                            cellSpacing={0}
                                            role="presentation"
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  className="data"
                                                  align="center"
                                                  style={{
                                                    fontSize: 23,
                                                    // fontFamily:
                                                    //   '"Lato",Helvetica,Arial,sans-serif',
                                                    fontWeight: 700,
                                                  }}
                                                >
                                                  <a
                                                    href={
                                                      RssData?.data["2"]?.URL
                                                    }
                                                    target="_blank"
                                                    className="title data"
                                                    style={{
                                                      color: "#00001b",
                                                      textDecoration: "none",
                                                    }}
                                                  >
                                                    {RssData?.data["2"]?.title}
                                                  </a>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td
                                                  className="data"
                                                  align="center"
                                                  style={{
                                                    fontSize: 18,
                                                    // fontFamily:
                                                    //   '"Lato",Helvetica,Arial,sans-serif',
                                                    fontWeight: 700,
                                                    padding: "18px 0 10px",
                                                  }}
                                                >
                                                  <a
                                                    className="data"
                                                    href={
                                                      RssData?.data["2"]?.URL
                                                    }
                                                    target="_blank"
                                                    style={{
                                                      color: "#0179d9",
                                                      textDecoration: "none",
                                                    }}
                                                  >
                                                    Read More
                                                  </a>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td className="data">
                                                  <table
                                                    width={90}
                                                    align="center"
                                                    border={0}
                                                    cellPadding={0}
                                                    cellSpacing={0}
                                                    role="presentation"
                                                  >
                                                    <tbody>
                                                      <tr>
                                                        <td
                                                          className="data"
                                                          height={2}
                                                          bgcolor="#0179d9"
                                                          style={{
                                                            fontSize: 1,
                                                            lineHeight: 1,
                                                          }}
                                                        >
                                                          &nbsp;
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </>
                                  )}
                                  <tr>
                                    <td
                                      className="data"
                                      bgcolor="#b6b2b2"
                                      height={1}
                                      style={{ fontSize: 1, lineHeight: 1 }}
                                    >
                                      &nbsp;
                                    </td>
                                  </tr>
                                  {/* Article End */}
                                  {/* Article Start */}
                                  {RssData?.data["3"]?.URL && (
                                    <>
                                      <tr>
                                        <td
                                          className="data"
                                          style={{ padding: "35px 0 30px" }}
                                        >
                                          <table
                                            width="100%"
                                            border={0}
                                            cellPadding={0}
                                            cellSpacing={0}
                                            role="presentation"
                                          >
                                            <tbody>
                                              {/* {RssData?.data["3"]?.URL && (<> */}

                                              <tr>
                                                <td
                                                  className="data"
                                                  align="center"
                                                  style={{
                                                    fontSize: 23,
                                                    // fontFamily:
                                                    //   '"Lato",Helvetica,Arial,sans-serif',
                                                    fontWeight: 700,
                                                  }}
                                                >
                                                  <a
                                                    href={
                                                      RssData?.data["3"]?.URL
                                                    }
                                                    className="title data"
                                                    target="_blank"
                                                    style={{
                                                      color: "#00001b",
                                                      textDecoration: "none",
                                                    }}
                                                  >
                                                    {RssData?.data["3"]?.title}
                                                  </a>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td
                                                  className="data"
                                                  align="center"
                                                  style={{
                                                    fontSize: 18,
                                                    // fontFamily:
                                                    //   '"Lato",Helvetica,Arial,sans-serif',
                                                    fontWeight: 700,
                                                    padding: "18px 0 10px",
                                                  }}
                                                >
                                                  <a
                                                    className="data"
                                                    href={
                                                      RssData?.data["3"]?.URL
                                                    }
                                                    target="_blank"
                                                    style={{
                                                      color: "#0179d9",
                                                      textDecoration: "none",
                                                    }}
                                                  >
                                                    Read More
                                                  </a>
                                                </td>
                                              </tr>
                                              {/* </>)} */}
                                              <tr>
                                                <td className="data">
                                                  <table
                                                    width={90}
                                                    align="center"
                                                    border={0}
                                                    cellPadding={0}
                                                    cellSpacing={0}
                                                    role="presentation"
                                                  >
                                                    <tbody>
                                                      <tr>
                                                        <td
                                                          className="data"
                                                          height={2}
                                                          bgcolor="#0179d9"
                                                          style={{
                                                            fontSize: 1,
                                                            lineHeight: 1,
                                                          }}
                                                        >
                                                          &nbsp;
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </>
                                  )}
                                  <tr>
                                    <td
                                      className="data"
                                      bgcolor="#b6b2b2"
                                      height={1}
                                      style={{ fontSize: 1, lineHeight: 1 }}
                                    >
                                      &nbsp;
                                    </td>
                                  </tr>
                                  {/* Article End */}
                                  {/* Article Start */}
                                  {RssData?.data["4"]?.URL && (
                                    <>
                                      <tr>
                                        <td
                                          className="data"
                                          style={{ padding: "35px 0 30px" }}
                                        >
                                          <table
                                            width="100%"
                                            border={0}
                                            cellPadding={0}
                                            cellSpacing={0}
                                            role="presentation"
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  className="data"
                                                  align="center"
                                                  style={{
                                                    fontSize: 23,
                                                    // fontFamily:
                                                    //   '"Lato",Helvetica,Arial,sans-serif',
                                                    fontWeight: 700,
                                                  }}
                                                >
                                                  <a
                                                    href={
                                                      RssData?.data["4"]?.URL
                                                    }
                                                    target="_blank"
                                                    className="title data"
                                                    style={{
                                                      color: "#00001b",
                                                      textDecoration: "none",
                                                    }}
                                                  >
                                                    {RssData?.data["4"]?.title}
                                                  </a>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td
                                                  className="data"
                                                  align="center"
                                                  style={{
                                                    fontSize: 18,
                                                    // fontFamily:
                                                    //   '"Lato",Helvetica,Arial,sans-serif',
                                                    fontWeight: 700,
                                                    padding: "18px 0 10px",
                                                  }}
                                                >
                                                  <a
                                                    className="data"
                                                    href={
                                                      RssData?.data["4"]?.URL
                                                    }
                                                    target="_blank"
                                                    style={{
                                                      color: "#0179d9",
                                                      textDecoration: "none",
                                                    }}
                                                  >
                                                    Read More
                                                  </a>
                                                </td>
                                              </tr>

                                              <tr>
                                                <td className="data">
                                                  <table
                                                    width={90}
                                                    align="center"
                                                    border={0}
                                                    cellPadding={0}
                                                    cellSpacing={0}
                                                    role="presentation"
                                                  >
                                                    <tbody>
                                                      <tr>
                                                        <td
                                                          className="data"
                                                          height={2}
                                                          bgcolor="#0179d9"
                                                          style={{
                                                            fontSize: 1,
                                                            lineHeight: 1,
                                                          }}
                                                        >
                                                          &nbsp;
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>{" "}
                                    </>
                                  )}
                                  <tr>
                                    <td
                                      className="data"
                                      bgcolor="#b6b2b2"
                                      height={1}
                                      style={{ fontSize: 1, lineHeight: 1 }}
                                    >
                                      &nbsp;
                                    </td>
                                  </tr>
                                  {/* Article End */}
                                  {/* Article Start */}
                                  {RssData?.data["5"]?.URL && (
                                    <>
                                      <tr>
                                        <td
                                          className="data"
                                          style={{ padding: "35px 0 30px" }}
                                        >
                                          <table
                                            width="100%"
                                            border={0}
                                            cellPadding={0}
                                            cellSpacing={0}
                                            role="presentation"
                                          >
                                            <tbody>
                                              <tr>
                                                <td
                                                  className="data"
                                                  align="center"
                                                  style={{
                                                    fontSize: 23,
                                                    // fontFamily:
                                                    //   '"Lato",Helvetica,Arial,sans-serif',
                                                    fontWeight: 700,
                                                  }}
                                                >
                                                  <a
                                                    href={
                                                      RssData?.data["5"]?.URL
                                                    }
                                                    target="_blank"
                                                    className="title data"
                                                    style={{
                                                      color: "#00001b",
                                                      textDecoration: "none",
                                                    }}
                                                  >
                                                    {RssData?.data["5"]?.title}
                                                  </a>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td
                                                  className="data"
                                                  align="center"
                                                  style={{
                                                    fontSize: 18,
                                                    // fontFamily:
                                                    //   '"Lato",Helvetica,Arial,sans-serif',
                                                    fontWeight: 700,
                                                    padding: "18px 0 10px",
                                                  }}
                                                >
                                                  <a
                                                    className="data"
                                                    href={
                                                      RssData?.data["5"]?.URL
                                                    }
                                                    target="_blank"
                                                    style={{
                                                      color: "#0179d9",
                                                      textDecoration: "none",
                                                    }}
                                                  >
                                                    Read More
                                                  </a>
                                                </td>
                                              </tr>
                                              <tr>
                                                <td className="data">
                                                  <table
                                                    width={90}
                                                    align="center"
                                                    border={0}
                                                    cellPadding={0}
                                                    cellSpacing={0}
                                                    role="presentation"
                                                  >
                                                    <tbody>
                                                      <tr>
                                                        <td
                                                          className="data"
                                                          height={2}
                                                          bgcolor="#0179d9"
                                                          style={{
                                                            fontSize: 1,
                                                            lineHeight: 1,
                                                          }}
                                                        >
                                                          &nbsp;
                                                        </td>
                                                      </tr>
                                                    </tbody>
                                                  </table>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </td>
                                      </tr>
                                    </>
                                  )}
                                  <tr>
                                    <td
                                      className="data"
                                      bgcolor="#b6b2b2"
                                      height={1}
                                      style={{ fontSize: 1, lineHeight: 1 }}
                                    >
                                      &nbsp;
                                    </td>
                                  </tr>
                                  {/* Article End */}
                                  {/* Article Start */}
                                  {/* <tr>
                                    <td
                                      className="data"
                                      style={{ padding: "35px 0 30px" }}
                                    >
                                      <table
                                        width="100%"
                                        border={0}
                                        cellPadding={0}
                                        cellSpacing={0}
                                        role="presentation"
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              className="data"
                                              align="center"
                                              style={{
                                                fontSize: 23,
                                                // fontFamily:
                                                //   '"Lato",Helvetica,Arial,sans-serif',
                                                fontWeight: 700,
                                              }}
                                            >
                                              <a
                                                href="[-ARTICLE_SIX_LINK-]"
                                                className="title data"
                                                style={{
                                                  color: "#00001b",
                                                  textDecoration: "none",
                                                }}
                                              >
                                                [-ARTICLE_SIX_TEXT-]
                                              </a>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              className="data"
                                              align="center"
                                              style={{
                                                fontSize: 18,
                                                // fontFamily:
                                                //   '"Lato",Helvetica,Arial,sans-serif',
                                                fontWeight: 700,
                                                padding: "18px 0 10px",
                                              }}
                                            >
                                              <a
                                                className="data"
                                                href="[-ARTICLE_SIX_LINK-]"
                                                target="_blank"
                                                style={{
                                                  color: "#0179d9",
                                                  textDecoration: "none",
                                                }}
                                              >
                                                Read More
                                              </a>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td className="data">
                                              <table
                                                width={90}
                                                align="center"
                                                border={0}
                                                cellPadding={0}
                                                cellSpacing={0}
                                                role="presentation"
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td
                                                      className="data"
                                                      height={2}
                                                      bgcolor="#0179d9"
                                                      style={{
                                                        fontSize: 1,
                                                        lineHeight: 1,
                                                      }}
                                                    >
                                                      &nbsp;
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr> */}
                                  <tr>
                                    <td
                                      className="data"
                                      bgcolor="#b6b2b2"
                                      height={1}
                                      style={{ fontSize: 1, lineHeight: 1 }}
                                    >
                                      &nbsp;
                                    </td>
                                  </tr>
                                  {/* Article End */}
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          {/* Podcast Promo */}
                          <tr>
                            <td
                              className="data"
                              align="center"
                              style={{ padding: "30px 10px 35px" }}
                            >
                              <a
                                className="data"
                                href="https://www.physiciansweekly.com/podcast/"
                                target="_blank"
                              >
                                <img
                                  src={images.Postup_Footer_Podcast}
                                  className="full_img"
                                  alt=""
                                  width={376}
                                  style={{
                                    display: "block",
                                    height: "auto",
                                    border: 0,
                                    margin: "0 auto",
                                  }}
                                />
                              </a>
                            </td>
                          </tr>
                          {/* //Podcast Promo */}
                          {/* <tr>
                          <td className="data" align="center"><a href="[-FULL_ISSUE-]" target="_blank"><img src="[-image:/Templates/Wallboard/api_template/assets/download_btn.png-]" class="full_img" alt="" width="371" style="display:block;height:auto;border:0;"></a></td>
                      </tr> */}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  {/* Advertisement */}
                  <tr>
                    <td
                      className="data"
                      bgcolor="#ffffff"
                      align="center"
                      style={{
                        color: "#231f20",
                        fontSize: 12,
                        // fontFamily: '"Lato",Helvetica,Arial,sans-serif',
                        paddingTop: 36,
                      }}
                    >
                      Advertisement
                    </td>
                  </tr>
                  <tr>
                    <td
                      className="data"
                      bgcolor="#ffffff"
                      style={{ padding: "10px 0 20px" }}
                    >
                      {/* JEENG 300x250 */}
                      <div className="pi_43598 jeeng">
                        {/* domain: physiciansweekly.com */}
                        {/*[if (gte mso 9)|(IE)]><table align="center" cellpadding="0" cellspacing="0" width="300"><tr><td className="data"><![endif]*/}
                        <table
                          width={300}
                          align="center"
                          border={0}
                          cellPadding={0}
                          cellSpacing={0}
                          style={{ width: "100%", maxWidth: 300 }}
                        >
                          <tbody>
                            <tr>
                              <td className="data" align="center">
                                <a
                                  className="data"
                                  href={`https://rs-stripe.physiciansweekly.com/stripe/redirect?cs_stripeid=43598&cs_offset=0&cs_email=${RssData?.item?.Email}&cs_sendid=${RssData?.data?.MAILINGID}&cs_esp=postup&dfp_postupid=${RssData?.data?.MAILING_REFERENCE_NUMBER}`}
                                  style={{
                                    borderStyle: "none",
                                    outline: "none",
                                    textDecoration: "none",
                                  }}
                                  target="_blank"
                                  rel="nofollow noopener"
                                >
                                  <img
                                    alt=""
                                    height="auto"
                                    src={`https://rs-stripe.physiciansweekly.com/stripe/image?cs_stripeid=43598&cs_offset=0&cs_email=${RssData?.item?.Email}&cs_sendid=${RssData?.data?.MAILINGID}&cs_esp=postup&dfp_postupid=${RssData?.data?.MAILING_REFERENCE_NUMBER}`}
                                    style={{
                                      display: "block",
                                      border: 0,
                                      height: "auto",
                                      lineHeight: "100%",
                                      outline: "none",
                                      textDecoration: "none",
                                      width: "100%",
                                      maxWidth: 300,
                                    }}
                                    width={300}
                                  />
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        {/*[if (gte mso 9)|(IE)]></td></tr></table><![endif]*/}
                      </div>
                      {/* JEENG 300x250 */}
                    </td>
                  </tr>
                  {/* //Advertisement */}
                  {/* Optional Advertisement */}
                  {/* <tr>
              <td className="data" bgcolor="#ffffff" style="padding-top:30px;"><img src="https://dummyimage.com/600x170/dddddd/666666.jpg&text=Ad+Placeholder" width="600" style="display:block;height:auto;border:0;"></td>
          </tr> */}
                  {/* //Optional Advertisement */}
                  {/* Footer */}
                  <tr>
                    <td
                      className="data"
                      bgcolor="#ffffff"
                      style={{ padding: "44px 10px" }}
                    >
                      <table
                        width="100%"
                        border={0}
                        cellPadding={0}
                        cellSpacing={0}
                        role="presentation"
                      >
                        <tbody>
                          <tr>
                            <td
                              className="data"
                              align="center"
                              style={{
                                color: "#231f20",
                                fontSize: 15,
                                // fontFamily: '"Lato",Helvetica,Arial,sans-serif',
                                // lineHeight: 22,
                              }}
                            >
                              <span
                                className="data"
                                style={{ fontWeight: 700 }}
                              >
                                Physician's Weekly
                              </span>
                              <br />
                              180 Mt. Airy Rd., Suite 205
                              <br />
                              Basking Ridge, NJ 07920
                              <br />© copyright {year} Physician's Weekly
                              <br />
                              all rights reserved
                            </td>
                          </tr>
                          <tr>
                            <td
                              className="data"
                              align="center"
                              style={{
                                color: "#231f20",
                                fontSize: 15,
                                // fontFamily: '"Lato",Helvetica,Arial,sans-serif',
                                paddingTop: 30,
                              }}
                            >
                              <a
                                className="data"
                                href={`https://my.preferencecentral.com/physiciansweekly/Preferences?email=${RssData?.item?.Email}&recipient_id=[-RECIPID-]`}
                                target="_blank"
                                style={{
                                  color: "#231f20",
                                  textDecoration: "underline",
                                }}
                              >
                                Preferences
                              </a>
                              &nbsp;&nbsp;|&nbsp;&nbsp;
                              <a
                                className="data"
                                href="[-WEB_UNSUB-]"
                                target="_blank"
                                style={{
                                  color: "#231f20",
                                  textDecoration: "underline",
                                }}
                              >
                                Unsubscribe
                              </a>
                              &nbsp;&nbsp;|&nbsp;&nbsp;
                              <a
                                className="data"
                                href="https://www.physiciansweekly.com/privacy-policy/"
                                target="_blank"
                                style={{
                                  color: "#231f20",
                                  textDecoration: "underline",
                                }}
                              >
                                Privacy Policy
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  {/* //Footer */}
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default RSSFeedPopup;
