
// images.js

const images = {
    Template1: require('../images/media/Template1.png'),
    Temp1: require('../images/media/temp1.png'),
    Temp2: require('../images/media/temp2.png'),
    ICYMI: require('../images/media/ICYMI.png'),
    ICYMI_1_CARTOON: require('../images/media/1ICYMI_CARtoon.png'), 
    ICYMI_Internal: require('../images/media/ICYMI Internal.png'), 
    ICYMI_2:require('../images/media/2-ICYMI.png'),
    ICYMI_1: require('../images/media/1CYMI.png'),
    ICYMI_2Cartoon:require('../images/media/2ICYMI_Cartoon.png'),
    I2AD_ICYMIL:require('../images/media/I-2AD-ICYMI.png'),
    PW_FAV_ICON:require('../images/media/PWfav-icon-new.png'),
    Postup_Banner: require('../images/media/PW_postup_logo.png'),
    Postup_Advertisement: require('../images/media/Postup_advertisement.jpg'),
    Postup_Footer_Podcast: require('../images/media/PW_Footer_podcast.png'),
    AI_ICON: require('../images/media/AI_ICON.png'),
    AI_Green_ICON: require('../images/media/AI_ICON_Green.png'),
    Empty_Space: require('../images/media/EmptySpace.png'),
  };
  
  export default images;
  