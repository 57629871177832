import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
  Pagination,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { PiFileCsvBold } from "react-icons/pi";
import { saveAs } from "file-saver";
import { useTheme } from "@mui/material/styles";

// Components
import MainCard from "ui-component/cards/MainCard";
import Message from "components/Snackbar/Snackbar";
import Loading from "components/Loading";
import Popup from "components/Popup";
import PrePrintForm from "./../PrePrintForm";
import { MENU_OPEN } from "store/actions";
import { PrePrintListingFilter, ViewAllFilter } from "store/Data";
import { requiredFields, Postup_Email_List, RSS_feed_fields } from "../Fields";
import { API, Postup_APIS } from "api/API";
import Axios from "api/Axios";
import { monthss } from "../Fields";
import "../style.css";
import { GiConsoleController } from "react-icons/gi";
import { IoEye } from "react-icons/io5";
import { Link } from "react-router-dom";

const validationSchema = Yup.object().shape({
  poster_Name: Yup.string().required("Poster Name is required"),
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
});

const ViewRSSFeed = ({ ...others }) => {
  const location = useLocation();
  const theme = useTheme();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [showData, setShowData] = useState([]);
  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);
  const [next, setNext] = useState();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [getValues, setValues] = useState({ month: "", year: "", printid: "" });
  const [printData, setPrintData] = useState();
  const [getFileNames, setFileNames] = useState([]);
  const [url, setURL] = useState(
    `${decodeURIComponent(location.search).substring(1)}`
  );
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    // severity: "",
  });


  const handleCloseSnackbar = () => {
    setSnackbar({
      open: false,
      message: "",
      severity: snackbar.severity,
    });
  };

  const dispatch = useDispatch();
  const params = useParams();

  const serialNumber = (page, index) => {
    return (page - 1) * rowsPerPage + index + 1;
  };

  const data = useSelector((state) => state.Data.PrePrintListingFilter);
  const viewAll = useSelector((state) => state.Data.ViewAllFilter);

  const handleChangePage = async (event, newPage) => {
    try {
      setPage(newPage);
      await Get_All_listings(newPage);
    } catch (error) {
      console.log("Error in Pagination", error);
    }
  };

  const Get_All_listings = async (page) => {
    const state = location.state;
    try {
      setIsLoading(true);
      const response = await Axios.post(
        `${Postup_APIS.Get_RSS_feed}?page=${page}`,
        {
          file_name: state?.file_name,
          PACKAGE_ID: state?.PACKAGE_ID,
          Email: state?.Email,
        }
      );
      if (response.status === 200) {
        setShowData(response.data.data.tags);
        setNext(response?.data);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err, "Error while getting Categories");
      setIsLoading(false);
      if (err.response.status === 404) {
        setShowData([]);
      }
    }
  };

  const GetRWOFiles = async () => {
    try {
      setIsLoading(true);
      const response = await Axios.get(API.RWO_Files);
      if (response.status === 200) {
        setFileNames(response.data.fileNames);
      }
    } catch (err) {
      console.log(err, "Error while getting Files");
      setIsLoading(false);
    }
  };

  const All_CSV_data = async (page) => {
    try {
      setIsLoading(true);
      const response = await Axios.get(`${API.View_CSV_All_Data}?page=${page}`);
      if (response.status === 200) {
        setShowData(response.data.data);
        setNext(response?.data);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err, "Error while getting Files");
      setIsLoading(false);
    }
  };


  const columns = [
    // { id: "sno", label: "#" },
    ...RSS_feed_fields.map((item) => ({ id: item, label: item })),
  ];

  useEffect(() => {
    Get_All_listings(page);
    dispatch({ type: MENU_OPEN, id: "postup" });
  }, []);

  return (
    <MainCard title="RSS Feed">
      <TableContainer
        className="chips"
        component={Paper}
        style={{ border: "1px solid black" }}
      >
        <Table>
          <TableHead>
            <TableRow style={{ background: "#364152", border: "none" }}>
              {columns.map((column) => (
                <TableCell
                  style={{ textAlign: "center", color: "#fff", border: "none" }}
                  className="headings"
                  // style={{ color: "#fff" }}
                  key={column.id}
                >
                                  <div dangerouslySetInnerHTML={{ __html: column.label }} />
                  {/* {column.label} */}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {/* {showData && showData.length > 0 ? (
              showData.map((item, index) => {
                return ( */}
                {showData  ? (
                  <TableRow>
                    {/* <TableCell
                      style={{ textAlign: "center" }}
                      className="common"
                      // padding="checkbox"
                    >
                      {serialNumber(page, index)}
                    </TableCell> */}

                    <TableCell className="common">{showData?.FirstName}</TableCell>
                    <TableCell className="common">{showData?.LastName}</TableCell>
                    <TableCell className="common">{showData?.emailaddr}</TableCell>
                    <TableCell className="common">{showData?.MAILINGID}</TableCell>
                    <TableCell className="common">{showData?.MAILING_REFERENCE_NUMBER}</TableCell>
                    <TableCell className="common">{showData?.poster_name}</TableCell>
                    <TableCell className="common">
                      <a target="_blank" href={showData?.ARTICLE_ONE_IMAGE}>{showData?.ARTICLE_ONE_IMAGE}</a>
                    </TableCell>
                    <TableCell className="common">
                    <a target="_blank" href={showData?.ARTICLE_ONE_LINK}>{showData?.ARTICLE_ONE_LINK}</a>                      </TableCell>
                    <TableCell className="common">{showData?.ARTICLE_ONE_TEXT}</TableCell>
                    <TableCell className="common">
                    <a target="_blank" href={showData?.ARTICLE_TWO_LINK}>{showData?.ARTICLE_TWO_LINK}</a>
                    </TableCell>
                    <TableCell className="common">{showData?.ARTICLE_TWO_TEXT}</TableCell>
                    <TableCell className="common">
                    <a target="_blank" href={showData?.ARTICLE_THREE_LINK}>{showData?.ARTICLE_THREE_LINK}</a>
                    </TableCell>
                    <TableCell className="common">{showData?.ARTICLE_THREE_TEXT}</TableCell>
                    <TableCell className="common">
                    <a target="_blank" href={showData?.ARTICLE_FOUR_LINK}>{showData?.ARTICLE_FOUR_LINK}</a>
                    </TableCell>
                    <TableCell className="common">{showData?.ARTICLE_FOUR_TEXT}</TableCell>
                    <TableCell className="common">
                    <a target="_blank" href={showData?.ARTICLE_FIVE_LINK}>{showData?.ARTICLE_FIVE_LINK}</a>
                    </TableCell>
                    <TableCell className="common">{showData?.ARTICLE_FIVE_TEXT}</TableCell>
                    <TableCell className="common">
                    <a target="_blank" href={showData?.ARTICLE_SIX_LINK}>{showData?.ARTICLE_SIX_LINK}</a>
                    </TableCell>
                    <TableCell className="common">{showData?.ARTICLE_SIX_TEXT}</TableCell>
                  </TableRow>
                // );
            //   })
            ) : (
              <TableRow>
                <TableCell style={{ textAlign: "center" }} colSpan={10}>
                  Record Not Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Popup
        title="Please enter the details"
        open={open1}
        overflowY="auto"
        // height="365px"
        content={
          <>
            <PrePrintForm
              setIsLoading={setIsLoading}
              close={() => setOpen1(false)}
              value={getValues}
              setSnackbar={setSnackbar}
              printData={printData}
            />
          </>
        }
      />

      {showData?.length > 0 && next?.totalCount > 10 && (
        <Pagination
          count={next?.totalPages}
          defaultPage={1}
          page={page}
          onChange={handleChangePage}
          siblingCount={1}
          boundaryCount={1}
          color="primary"
          className="paging"
          sx={{
            button: { mt: 2 },
            width: "100%",
            display: "flex",
            justifyContent: { xs: "center", md: "flex-end" },
          }}
        />
      )}

      <Message snackbar={snackbar} handleCloseSnackbar={handleCloseSnackbar} />
      <Loading isLoading={isLoading} height={80} width={80} color="#15223F" />
    </MainCard>
  );
};

export default ViewRSSFeed;
