import React, { useState, useRef } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { FaFilePdf } from "react-icons/fa6";
import { API, Perks_APIS } from "api/API";
import Axios from "api/Axios";

import "../PrePrint/style.css";

const validationSchema = Yup.object().shape({
  pdf_file: Yup.mixed()
    .required("PDF File is required")
    .test(
      "fileType",
      "Only PDF files are allowed",
      (value) => value && value.type === "application/pdf"
    ),
  // .test(
  //   "fileSize",
  //   "File too large, max size is 5MB",
  //   (value) => value && value.size <= 5242880
  // )
});

const UploadPDF = ({
  close,
  setSnackbar,
  setIsLoading,
  uploadAssetsData,
  setassetsData,
  ChildListing,
  isLoading,
  SubListing,
  uploadCheck,
  PosterAssets,
  ...others
}) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const [data, setData] = useState([]);
  const [template, setTemplate] = useState([]);
  const tagsContainerRef = useRef(null);

  const replaceSlashWithDash = (inputString) => {
    return inputString.replace(/\//g, "-");
  };

  // Without Validation
  const createFileChangeHandler = (fieldName, setFieldValue) => (event) => {
    const file = event.target.files[0];
    if (file && file.name.toLowerCase().endsWith(".pdf")) {
      const fileNameContainsPostCode = file.name.includes(
        replaceSlashWithDash(PosterAssets.POSTER_CODE_1)
      );
      if (fileNameContainsPostCode) {
        setFieldValue(fieldName, file);
      } else {
        setSnackbar({
          open: true,
          severity: "error",
          message: `The file name must include ${replaceSlashWithDash(
            PosterAssets.POSTER_CODE_1
          )}`,
        });
      }
    } else {
      setSnackbar({
        open: true,
        severity: "error",
        message: `Please select PDF Only for File`,
      });
    }
  };

  // With Validation
  //   const createFileChangeHandler = (fieldName, setFieldValue) => (event) => {
  //     const file = event.target.files[0];
  //     if (file && file.name.toLowerCase().endsWith(".pdf")) {
  //       let poster_code = replaceSlashWithDash(uploadAssetsData.post_code);
  //       const month = uploadAssetsData.month.slice(0, 3).toUpperCase();
  //       const year = uploadAssetsData.year.slice(2);
  //       const expectedFileName = `${poster_code}_${month}${year}_${
  //         fieldName === "manual_file" ? "Manual" : "Ad_1"
  //       }.pdf`;
  //       if (file.name === expectedFileName) {
  //         setFieldValue(fieldName, file);
  //       } else {
  //         setSnackbar({
  //           open: true,
  //           severity: "error",
  //           message: `Incorrect file name: Suggested File Name is ${expectedFileName}.`,
  //         });
  //         event.target.value = "";
  //       }
  //     } else {
  //       setSnackbar({
  //         open: true,
  //         severity: "error",
  //         message: `Please select PDF Only for ${fieldName} File`,
  //       });
  //       event.target.value = "";
  //     }
  //   };

  // with Edit function
  const handleSubmit = async (values) => {
    console.log(uploadAssetsData.file_name);
    const formData = new FormData();
    formData.append("pdf", values.pdf_file);
    formData.append("poster_code", PosterAssets?.POSTER_CODE_1);

    try {
      setIsLoading(true);
      const response = await Axios.Filepost(
        Perks_APIS.Upload_Perks_PDF,
        formData
      );
      if (response.status === 200) {
        setIsLoading(false);
        // console.log(response.data, "response");
        setSnackbar({
          open: true,
          message: response.data.message,
          severity: "success",
        });
        await ChildListing(uploadAssetsData?.file_name);
        await SubListing(uploadCheck?.merged_name);
        close();
      }
    } catch (err) {
      console.log(err, "Error while getting API response");
      setIsLoading(false);
      setSnackbar({
        open: true,
        message: err.response.data.error,
        severity: "error",
      });
    }
  };

  return (
    <div style={{ marginTop: -28 }}>
      <Formik
        initialValues={{
          pdf_file: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          handleSubmit(values);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          touched,
          values,
        }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            {...others}
            autoComplete="off"
          >
            <Grid
              container
              spacing={matchDownSM ? 0 : 2}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Grid item xs={data.manual ? 11 : 12}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.pdf_file && errors.pdf_file)}
                  sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-pdf_file">
                    PDF File
                  </InputLabel>
                  <Input
                    id="outlined-adornment-pdf_file"
                    type="file"
                    name="pdf_file"
                    inputProps={{
                      accept: ".pdf",
                    }}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      createFileChangeHandler("pdf_file", setFieldValue)(e);
                    }}
                    endAdornment={
                      <InputAdornment
                        sx={{ fontSize: 22, marginTop: 2 }}
                        position="end"
                      >
                        <FaFilePdf />
                      </InputAdornment>
                    }
                  />
                  {touched.pdf_file && errors.pdf_file && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-pdf_file"
                    >
                      {errors.pdf_file}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <div style={{ textAlign: "center", marginTop: 10 }}>
              <Button
                size="large"
                variant="contained"
                style={{
                  background: "#C62828",
                  marginRight: "10px",
                  color: isLoading === true && "#fff",
                }}
                onClick={close}
                disabled={isLoading === true ?? true}
              >
                Close
              </Button>
              <Button
                size="large"
                type="submit"
                variant="contained"
                style={{
                  background: "#1d213e",
                  marginRight: "15px",
                  color: isLoading === true && "#fff",
                }}
                disabled={isLoading === true ?? true}
              >
                Submit
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default UploadPDF;
