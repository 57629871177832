import React from "react";
import { useState } from "react";
import {
  Button,
  TextField,
  Box,
  Grid,
  FormControl,
  InputLabel,
  FormHelperText,
  OutlinedInput,
  useMediaQuery,
  Input,
  InputAdornment,
} from "@mui/material";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTheme } from "@emotion/react";
import Axios from "api/Axios";
import { API } from "api/API";

// const validationSchema = Yup.object().shape({
//     QR1: Yup.number()
//     .required("Post Order  is required"),
//     "QR5-3COL": Yup.number()
//     .required("Batch Number  is required"),
// });

const EditQRDetails = ({
  close,
  setIsLoading,
  setSnackbar,
  EditQRFields,
  isLoading,
  getData,
  uploadAssetsData,
  ...others
}) => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const handleEditDetails = async (values) => {
    // console.log(values, "hello I am the values");
    const datas = {
      newData: {
        merged_name: EditQRFields.merged_name,
        POSTER_CODE: EditQRFields.post_code,
        QR1: values.QR1,
        QR5_3COL: values["QR5-3COL"] === "" ? undefined : values["QR5-3COL"],
        "QR4-POLL": values["QR4-POLL"] === "" ? undefined : values["QR4-POLL"],
        file_name: uploadAssetsData.file_name,
        Article_1: values.Article_1, 
        Article_2: values.Article_2, 
        Article_3: values.Article_3, 
      },
    };
    try {
      setIsLoading(true);
      const response = await Axios.post(API.Edit_QR, datas);
      if (response.status === 200) {
        setIsLoading(false);
        setSnackbar({
          open: true,
          message: response.data.message,
          severity: "success",
        });
        getData();
        close();
      }
    } catch (err) {
      console.log(err, "Error while getting Categories");
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div style={{ marginTop: 7 }}>
        <Formik
          initialValues={{
            QR1: "" || EditQRFields.QR1,
            "QR5-3COL": "" || EditQRFields.QR5_3COL,
            "QR4-POLL": "" || EditQRFields.QR4_POLL,
            Article_1: "" || EditQRFields.Article_1,
            Article_2: "" || EditQRFields.Article_2,
            Article_3: "" || EditQRFields.Article_3,
            Article_6_Column_4_A3:"" ||EditQRFields.Article_6_Column_4_A3,
            Article_7_Column_4_A4:"" ||EditQRFields.Article_7_Column_4_A4,
          }}
          validationSchema={Yup.object().shape({
            QR1:
              EditQRFields.Template_Name === "1-ICYMI" ||
              EditQRFields.Template_Name === "1-ICYMI-CARTOON" ||
              EditQRFields.Template_Name === "2-ICYMI-POLL"
                ? Yup.string()
                    .url("QR1 must be a valid URL")
                    .required("QR1 is required")
                : null,
            "QR5-3COL":
              EditQRFields.Template_Name === "1-ICYMI" ||
              EditQRFields.Template_Name === "1-ICYMI-CARTOON"
                ? Yup.string()
                    .url("QR5-3COL must be a valid URL")
                    .required("QR5-3COL is required")
                : null,
            "QR4-POLL": Yup.string()
              .url("QR4-POLL must be a valid URL")
              .required("QR4-POLL is required"),
              Article_1: Yup.string()
              .url("Article 1 must be a valid URL")
              .required("Article 1 is required"),
              Article_2: Yup.string()
              .url("Article 2 must be a valid URL")
              .required("Article 2 is required"),
              Article_3: Yup.string()
              .url("Article 3 must be a valid URL")
              .required("Article 3 is required")
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            handleEditDetails(values);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            touched,
            values,
            dirty,
            resetForm,
          }) => (
            <form
              noValidate
              onSubmit={handleSubmit}
              {...others}
              autoComplete="off"
            >
              {/* <Grid container spacing={matchDownSM ? 0 : 2}> */}
              {EditQRFields.Template_Name === "1-ICYMI" && (
                <>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.QR1 && errors.QR1)}
                      sx={{ ...theme.typography.customInput }}
                    >
                      <InputLabel htmlFor="outlined-adornment-QR1">
                      QR1/Article 1/Column 1
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-QR1"
                        // type="text"
                        value={values.QR1}
                        name="QR1"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        inputProps={{}}
                      />
                      {touched.QR1 && errors.QR1 && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-QR1"
                        >
                          {errors.QR1}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl
                      fullWidth
                      error={Boolean(touched["QR5-3COL"] && errors["QR5-3COL"])}
                      sx={{ ...theme.typography.customInput }}
                    >
                      <InputLabel htmlFor="outlined-adornment-QR5-3COL">
                        QR5-3COL
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-QR5-3COL"
                        // type="number"
                        value={values["QR5-3COL"]}
                        name="QR5-3COL"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        inputProps={{}}
                      />
                      {touched["QR5-3COL"] && errors["QR5-3COL"] && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-QR5-3COL"
                        >
                          {errors["QR5-3COL"]}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </>
              )}

              {EditQRFields.Template_Name === "1-ICYMI-CARTOON" && (
                <>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.QR1 && errors.QR1)}
                      sx={{ ...theme.typography.customInput }}
                    >
                      <InputLabel htmlFor="outlined-adornment-QR1">
                        QR1
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-QR1"
                        // type="number"
                        value={values.QR1}
                        name="QR1"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        inputProps={{}}
                      />
                      {touched.QR1 && errors.QR1 && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-QR1"
                        >
                          {errors.QR1}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl
                      fullWidth
                      error={Boolean(touched["QR5-3COL"] && errors["QR5-3COL"])}
                      sx={{ ...theme.typography.customInput }}
                    >
                      <InputLabel htmlFor="outlined-adornment-QR5-3COL">
                        QR5-3COL
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-QR5-3COL"
                        // type="number"
                        value={values["QR5-3COL"]}
                        name="QR5-3COL"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        inputProps={{}}
                      />
                      {touched["QR5-3COL"] && errors["QR5-3COL"] && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-QR5-3COL"
                        >
                          {errors["QR5-3COL"]}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </>
              )}

              {EditQRFields.Template_Name === "2-ICYMI-POLL" && (
                <>
                  <Grid item xs={12} sm={6}>
                    <FormControl
                      fullWidth
                      error={Boolean(touched.QR1 && errors.QR1)}
                      sx={{ ...theme.typography.customInput }}
                    >
                      <InputLabel htmlFor="outlined-adornment-QR1">
                      QR1/Article 1/Column 1
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-QR1"
                        type="text"
                        value={values.QR1}
                        name="QR1"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        inputProps={{}}
                      />
                      {touched.QR1 && errors.QR1 && (
                        <FormHelperText
                          error
                          id="standard-weight-helper-text-QR1"
                        >
                          {errors.QR1}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                  
                <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.Article_6_Column_4_A3 && errors.Article_6_Column_4_A3)}
                  sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-Article_6_Column_4_A3">
                  Article 6/Column 4_A3
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-Article_6_Column_4_A3"
                    // type="number"
                    value={values.Article_6_Column_4_A3}
                    name="Article_6_Column_4_A3"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputProps={{}}
                  />
                  {touched.Article_6_Column_4_A3 && errors.Article_6_Column_4_A3 && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-Article_6_Column_4_A3"
                    >
                      {errors.Article_6_Column_4_A3}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.Article_7_Column_4_A4 && errors.Article_7_Column_4_A4)}
                  sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-Article_7_Column_4_A4">
                  Article 7/Column 4_A4
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-Article_7_Column_4_A4"
                    // type="number"
                    value={values.Article_7_Column_4_A4}
                    name="Article_7_Column_4_A4"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputProps={{}}
                  />
                  {touched.Article_7_Column_4_A4 && errors.Article_7_Column_4_A4 && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-Article_7_Column_4_A4"
                    >
                      {errors.Article_7_Column_4_A4}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
                </>
              )}

              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.Article_1 && errors.Article_1)}
                  sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-Article_1">
                  Article 2/Column 2
                    
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-Article_1"
                    // type="number"
                    value={values.Article_1}
                    name="Article_1"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputProps={{}}
                  />
                  {touched.Article_1 && errors.Article_1 && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-Article_1"
                    >
                      {errors.Article_1}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.Article_2 && errors.Article_2)}
                  sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-Article_2">
                  {/* Article 2/Column 2 */}
                  Article 4/Column 4_A1
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-Article_2"
                    // type="number"
                    value={values.Article_2}
                    name="Article_2"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputProps={{}}
                  />
                  {touched.Article_2 && errors.Article_2 && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-Article_2"
                    >
                      {errors.Article_2}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.Article_3 && errors.Article_3)}
                  sx={{ ...theme.typography.customInput }}
                >
                  <InputLabel htmlFor="outlined-adornment-Article_3">
                    Article 5/Column 4_A2
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-Article_3"
                    // type="number"
                    value={values.Article_3}
                    name="Article_3"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    inputProps={{}}
                  />
                  {touched.Article_3 && errors.Article_3 && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-Article_3"
                    >
                      {errors.Article_3}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <div style={{ textAlign: "center", marginTop: 10 }}>
                <Button
                  size="large"
                  variant="contained"
                  style={{
                    background: "#C62828",
                    marginRight: "10px",
                    color: isLoading === true && "#fff",
                  }}
                  disabled={isLoading === true ?? true}
                  onClick={close}
                >
                  Close
                </Button>
                <Button
                  size="large"
                  type="submit"
                  variant="contained"
                  style={{
                    background: "#1d213e",
                    marginRight: "15px",
                    color: isLoading === true && "#fff",
                  }}
                  disabled={isLoading === true ?? true}
                >
                  Submit
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EditQRDetails;
