import React from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const MonthYearPicker = ({ value, onChange,placeholder }) => {

  return (
    <DatePicker
      selected={value ?  new Date(value) : null}
      onChange={date => onChange(date)}
      dateFormat="MMMM YYYY"
      showMonthYearPicker
      showFullMonthYearPicker
      className="form-control"
      placeholderText={placeholder}
      // customInput={
      //   <div className="custom-date-input">
      //     {value ? formatDate(new Date(value)) : ""}
      //     {!value && <span className="placeholder-text">{placeholder}</span>}
      //   </div>
      // }
    />
  );
};

export default MonthYearPicker;