const STAGING = "dev"; // 'dev', 'prod', 'preview'

const KEY = {
  local: "zwzasj9gdq7nx31j6ba2apb0hu9ujd2s80pi4rzn83gb1taq",
  dev: "3cs1fcvikerr7ist1sjb0t7d1teah3w6jm2hi1ge2r0zoa3s",
  prod: "3idkm9av6xvnc1sl7yivo28kit75vwszguav0bup4jyshawm",
  preview: "sgkh8smqquffinx0wnfwkzdaywz5tgaxp7kiruh9r79fo7yr",
};

export const Keys = {
    EditorKey: KEY[STAGING] || ""
}