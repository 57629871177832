import React, { useState } from 'react';
import '../../Data Collection/style.css';
import { Button } from '@mui/material';
import Axios from 'api/Axios';
import { API, Meta_DataAPIS } from 'api/API';

const CreateVersions = ({
  isLoading,
  close,
  setIsLoading,
  uploadAssetsData,
  ChildListing,
  setSnackbar,
  item,
}) => {
  const [count, setCount] = useState(1);
  const increment = () => {
    setCount((prevCount) => prevCount + 1);
  };

  const decrement = () => {
    setCount((prevCount) => (prevCount > 1 ? prevCount - 1 : prevCount));
  };

  const GetMeta = async () => {
    const data = {
      file_name: uploadAssetsData?.file_name,
      poster_code: item.POSTER_CODE_1,
      batch_number: uploadAssetsData?.batch_number,
      post_order: uploadAssetsData?.post_order,
    };
    try {
      setIsLoading(true);
      const response = await Axios.post(Meta_DataAPIS.Get_Meta_Data, data);
      if (response.status === 200) {
        // console.log(response.data, "response");
        setIsLoading(false);
        return response.data.data;
      }
    } catch (err) {
      console.log(err, 'Error while QR Data');
      setIsLoading(false);
    }
  };

  const filterMetaData = (metaData) => {
    const excludedFields = [
      'Article_1',
      'Article_3',
      'Article_6_Column_4_A3',
      'Article_7_Column_4_A4',
    ];

    // Create a shallow copy of metaData to avoid mutating the original object
    const filteredMetaData = { ...metaData };

    // Remove excluded fields
    excludedFields.forEach((field) => {
      delete filteredMetaData[field];
    });

    return filteredMetaData;
  };

  const handleVersionCreate = async () => {
    const MetaData = await GetMeta();
    const filterData = filterMetaData(MetaData[0]);
    const data = {
      ...filterData,
      count,
    };
    try {
      setIsLoading(true);
      const response = await Axios.post(Meta_DataAPIS.Copy_Meta_DataViaCount, data);
      if (response.status === 200) {
        // console.log(response.data, "response");
        setSnackbar({
          open: true,
          message: response.data.message,
          severity: 'success',
        });
        close();
        await ChildListing();
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error, 'Error while creating versions');
      setIsLoading(false);
      setSnackbar({
        open: true,
        message: error?.message,
        severity: 'error',
      });
    }
  };

  return (
    <>
      <div className="Increment">
        <div className="counter-container">
          <button className="counter-button" onClick={decrement}>
            -
          </button>
          <span className="counter-value">{count}</span>
          <button className="counter-button" onClick={increment}>
            +
          </button>
        </div>
      </div>
      <div style={{ textAlign: 'center', marginTop: 10 }}>
        <Button
          size="large"
          variant="contained"
          style={{
            background: '#C62828',
            marginRight: '10px',
            color: isLoading === true && '#fff',
          }}
          onClick={close}
          disabled={isLoading === true ?? true}
        >
          Close
        </Button>
        <Button
          onClick={handleVersionCreate}
          size="large"
          variant="contained"
          style={{
            background: '#1d213e',
            marginRight: '15px',
            color: isLoading === true && '#fff',
          }}
          disabled={isLoading === true ?? true}
        >
          Submit
        </Button>
      </div>
    </>
  );
};

export default CreateVersions;
